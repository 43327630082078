import { Link } from "react-router-dom";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { useEffect, useRef, useState } from "react";
import ProductForSale from "./ProductForSale";
import Brand from "./Brand";
import { GetRequest, PostRequest } from "../../api/Request";
import Loader from "../../common/loader/Loader";
import { useSelector } from "react-redux";
import { allProduct } from "../../slice/CartSlice";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import HeroSection from "./HeroSection";
import { IsLoggedIn } from "../../slice/UserSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useMediaQuery } from "@react-hook/media-query";

const Home = (props: any) => {
  let { allCategory, subCategories, isLoadingForCategory } = props;
  const containerRef = useRef<any>(null);
  const isMobile = useMediaQuery("(max-width: 500px)");
  const subCategoryRef = useRef<any>(null);
  const [latestProducts, setLatestProducts] = useState<any>([]);
  const [trendingProducts, setTrendingProducts] = useState<any>([]);
  const [newProducts, setNewProducts] = useState<any>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [recommendedProducts, setRecommendedProducts] = useState<any>([]);
  const [isLoadingForRecommended, setIsLoadingForRecommended] = useState(false);
  const [allBanner, setAllBanner] = useState<any>([]);
  const [isLoadingForBanner, setIsLoadingForBanner] = useState<Boolean>(false);
  const cartProducts = useSelector(allProduct);
  const [isFading, setIsFading] = useState(false);
  const userIsLoggedIn = useSelector(IsLoggedIn);
  let url = process.env.PUBLIC_URL;

  const prevSlide = () => {
    setIsFading(true);
    setTimeout(() => {
      const isFirstSlide = currentIndex === 0;
      const newIndex = isFirstSlide ? allBanner.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
      setIsFading(false);
    }, 500); // Match this duration with your CSS transition duration
  };

  const nextSlide = () => {
    setIsFading(true);
    setTimeout(() => {
      const isLastSlide = currentIndex === allBanner.length - 1;
      const newIndex = isLastSlide ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
      setIsFading(false);
    }, 500); // Match this duration with your CSS transition duration
  };

  const getAllBanner = async () => {
    let url = "/getBannerAccToDevice?display_type=";
    url += isMobile ? "Mobile" : "Desktop";
    setIsLoadingForBanner(() => true);
    try {
      const response = await GetRequest(url);
      if (response.status === 200) {
        setAllBanner(() => response?.data);
      }
    } catch (error) {}
    setIsLoadingForBanner(() => false);
  };

  const getAllRecommendedProducts = async () => {
    setIsLoadingForRecommended(() => true);
    try {
      let response = await GetRequest("/recommended-products");
      if (response?.data?.products?.length > 0) {
        setRecommendedProducts(() => {
          return response?.data?.products?.map((product: any) => {
            const cartProduct = cartProducts.find(
              (cartItem: any) => cartItem._id === product._id
            );
            return {
              ...product,
              addedToWishlist: false,
              isLoading: false,
              quantityByUser: cartProduct ? cartProduct.quantityByUser : 1,
              addToCartStatus: cartProduct ? "Update Cart" : "Add to cart", // Set status based on cart presence
            };
          });
        });
      } else {
        setRecommendedProducts(() => []);
      }
    } catch (error) {}
    setIsLoadingForRecommended(() => false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [banners, latestProducts, trendingProducts, newProducts] =
          await Promise.all([
            getAllBanner(),
            getAllLatestProducts(),
            getAllTrendingProducts(),
            getAllNewProducts(),
          ]);

        // Handle the fetched data as needed
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      getAllRecommendedProducts();
    }
  }, [localStorage.getItem("userToken")]);

  const getAllLatestProducts = async () => {
    try {
      let response = await GetRequest("getAllProducts?latest=true");
      if (response.status === 200) {
        setLatestProducts(() => {
          return response?.data?.products?.docs.map((product: any) => {
            const cartProduct = cartProducts.find(
              (cartItem: any) => cartItem._id === product._id
            );
            return {
              ...product,
              addedToWishlist: false,
              isLoading: false,
              quantityByUser: cartProduct ? cartProduct.quantityByUser : 1,
              addToCartStatus: cartProduct ? "Update Cart" : "Add to cart", // Set status based on cart presence
            };
          });
        });
      }
    } catch (error) {}
  };

  const getAllTrendingProducts = async () => {
    try {
      let response = await GetRequest("getAllProducts?trending=true");
      if (response.status === 200) {
        setTrendingProducts(() => {
          return response?.data?.products?.docs.map((product: any) => {
            const cartProduct = cartProducts.find(
              (cartItem: any) => cartItem._id === product._id
            );
            return {
              ...product,
              addedToWishlist: false,
              isLoading: false,
              quantityByUser: cartProduct ? cartProduct.quantityByUser : 1,
              addToCartStatus: cartProduct ? "Update Cart" : "Add to cart", // Set status based on cart presence
            };
          });
        });
      }
    } catch (error) {
      return error;
    }
  };

  const getAllNewProducts = async () => {
    try {
      let response = await GetRequest("getAllProducts?newLaunches=true");
      if (response.status === 200) {
        setNewProducts(() => {
          return response?.data?.products?.docs.map((product: any) => {
            const cartProduct = cartProducts.find(
              (cartItem: any) => cartItem._id === product._id
            );
            return {
              ...product,
              addedToWishlist: false,
              isLoading: false,
              quantityByUser: cartProduct ? cartProduct.quantityByUser : 1,
              addToCartStatus: cartProduct ? "Update Cart" : "Add to cart", // Set status based on cart presence
            };
          });
        });
      }
    } catch (error) {}
  };

  const scrollLeft = (ref: any) => {
    if (ref.current) {
      ref.current.scrollBy({
        left: -400, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  const scrollRight = (ref: any) => {
    if (ref.current) {
      ref.current.scrollBy({
        left: 400, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const autoSlideInterval = setInterval(() => {
      nextSlide();
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(autoSlideInterval);
  }, [currentIndex, allBanner.length]);

  return (
    <div className="relative">
      <div className="relative">
        {/* <button
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          }}
          onClick={() => scrollRight(containerRef)}
          className="absolute right-[4px] sm:hidden text-[2rem] bg-white h-[70px] flex justify-center items-center w-[50px]  top-[50%] text-black transform translate-y-[-50%] z-10 opacity-[0.6]"
          aria-label="Scroll Right"
        >
          <MdOutlineKeyboardArrowRight />
        </button> */}
        <ul
          ref={containerRef}
          className=" overflow-y-hidden sm:justify-start overflow-x-auto mt-[7.9rem] sm:mt-[7rem] max-w-full flex justify-center pl-4 sm:pl-2 whitespace-nowrap no-scrollbar"
        >
          {allCategory?.length > 0 &&
            allCategory?.map((item: any, index: number) => {
              return (
                <li className={"category sm:!mr-[1rem]"}>
                  <Link
                    to={`product-list?category=${item.category}&category_id=${item._id}&page=1`}
                    className="flex justify-center items-center flex-col"
                  >
                    <LazyLoadImage
                      effect="blur"
                      src={item?.image?.url}
                      className="category-img"
                      alt="category"
                    />
                    <p className="text-[13px] font-[450] sm:max-w-[100px] mt-[0.3rem] sm:text-[11px] text-center">
                      {item?.category}
                    </p>
                  </Link>
                  {/* <Link
                    to={`product-list?category=${item.category}&category_id=${item._id}&page=1`}
                    className="flex justify-center items-center flex-col"
                  >
                    <img 
                      src={item.image.url}
                      className="category-img"
                      alt="category"
                    />
                    <p className="text-[13px] font-[450] sm:max-w-[70px] mt-[0.3rem] sm:text-[11px] text-center break-words">
                      {item?.category}
                    </p>
                    
                  </Link> */}
                </li>
              );
            })}
        </ul>

        {/* <button
          onClick={() => scrollLeft(containerRef)}
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          }}
          className="absolute opacity-[0.6] left-[4px] sm:hidden text-[2rem] bg-white h-[70px] flex justify-center items-center w-[50px]  top-[50%] text-black transform translate-y-[-50%] z-10"
          aria-label="Scroll Right"
        >
          <MdOutlineKeyboardArrowLeft />
        </button> */}
      </div>

      <div className="flex items-center mb-[2.5rem] top-[130px] z-0 sm:top-[83px] xs:top-[5rem] xss:top-[5rem] mt-[0.5rem] sm:my-[0.4rem] absolute">
        {isLoadingForBanner && allBanner?.length === 0 ? (
          <div className="flex justify-center items-center h-[200px] sm:h-[83px] sm:w-[83vw] w-[100vw] ">
            <Loader />
          </div>
        ) : (
          <div
            // className="w-[100vw] sm:h-[350px] cursor-pointer xs:h-[300px] xxl:h-[856px] xss:h-[250px] h-[800px] sm:mt-3 mt-6 sm:m-0 m-auto relative overflow-hidden"
            className="w-[100vw]  cursor-pointer  sm:mt-3 mt-6 sm:m-0 m-auto relative overflow-hidden"
          >
            <Link
              to={allBanner[currentIndex]?.banner_link}
              className="cursor-pointer"
            >
              <LazyLoadImage
                effect="blur"
                src={allBanner.length > 0 && allBanner[currentIndex].url}
                alt={allBanner[currentIndex]?.name}
                className={`w-[100vw] h-auto sm:h-[auto] z-[-1] cursor-pointer object-cover duration-500 transition-opacity ${
                  isFading ? "opacity-40" : "opacity-100"
                }`}
              />
            </Link>

            <div className="group-hover:block absolute top-[20%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
              <BsChevronCompactLeft
                onClick={allBanner.length > 0 ? prevSlide : () => {}}
                size={20}
              />
            </div>

            <div className="group-hover:block absolute top-[20%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
              <BsChevronCompactRight
                onClick={allBanner.length > 0 ? nextSlide : () => {}}
                size={20}
              />
            </div>
          </div>
        )}
      </div>

      <div className="sm:mt-[8rem] mt-[20rem] z-[999]">
        <div className="relative flex flex-wrap justify-around ">
          <HeroSection
            heading={"Appliances for your Home | Up to 22% Off"}
            info={[
              {
                name: "Air Conditioner",
                link: "https://cayroshop.com/product-list?sub-category-id=66b3023377bbedb89846e6b5&page=1",
                imgUrl: "ac.webp",
              },
              {
                name: "Ceiling Fan",
                link: "https://cayroshop.com/product-list?category=Home+Appliances&category_id=66b1ea535472ab860dac9d2d&page=1&maxPrice=2820&minPrice=2820",
                imgUrl: "ceiling_fan.webp",
              },
              {
                name: "Television",
                link: "https://cayroshop.com/product-list?category=Television&category_id=66b1e0915472ab860dac9d27&page=1",
                imgUrl: "television.webp",
              },

              {
                name: "Washing Machine",
                link: "https://cayroshop.com/product-list?sub-category-id=66b31d08b8d58b966e370d71&page=1",
                imgUrl: "washing_machine.webp",
              },
              ,
            ]}
          />
          <HeroSection
            heading={"Appliances for your Kitchen | Up to 33% Off"}
            info={[
              {
                name: "Refrigerator",
                link: "https://cayroshop.com/product-list?sub-category-id=66b3066977bbedb89846e6bd&page=1",
                imgUrl: "refrigerator.webp",
              },
              {
                name: "Microwave Oven",
                link: "https://cayroshop.com/product-list?sub-category-id=66b4720304b4b470f4f7440b&page=1",
                imgUrl: "microwave_oven.webp",
              },

              {
                name: "Blender",
                link: "https://cayroshop.com/product-list?sub-category-id=66b33d1bb8d58b966e3713d1&page=1",
                imgUrl: "blender.webp",
              },
              {
                name: "Water Purifier",
                link: "https://cayroshop.com/product-list?sub-category-id=6703b86a94d50edb56735524&page=1",
                imgUrl: "water_purifier.webp",
              },
            ]}
          />
          <HeroSection
            heading={"Items which you can gift"}
            info={[
              {
                name: "Mobiles",
                link: "https://cayroshop.com/product-list?category=Mobiles&category_id=66b1de805472ab860dac9d1c&page=1",
                imgUrl: "mobiles.webp",
              },
              {
                name: "Headphones",
                link: "https://cayroshop.com/product-list?sub-category-id=67067e7d0c095ef7c895c3ee&page=1",
                imgUrl: "headphone.webp",
              },
              {
                name: "Laptops",
                link: "https://cayroshop.com/product-list?category=Laptops&category_id=67067b7c915de597bb4bc686&page=1",
                imgUrl: "laptop.webp",
              },
              {
                name: "Smart Watch",
                link: "https://cayroshop.com/product-list?sub-category-id=670676d9ed6b987d03197b68&page=1",
                imgUrl: "smart_watch.webp",
              },
            ]}
          />
          <div
            style={{
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            }}
            className="relative basis-[18%]  sm:basis-[45%] p-[1rem] sm:p-[0.5rem] !pb-[0rem] bg-white mx-auto max-w-[95%] rounded-lg  mt-[1rem] h-[100%]"
          >
            {/* <div className="bg-white "> */}
            <h2 className="text-primary font-bold text-[17px] mb-[0.8rem] sm:text-[13px] xss:text-[10px]">
              {userIsLoggedIn
                ? "Discover unbeatable savings with our daily deals!"
                : "Sign in for your best experience"}
            </h2>
            <div className="flex justify-center sm:hidden items-center mb-[1rem]">
              <Link
                to={
                  userIsLoggedIn
                    ? "https://cayroshop.com/product-list?category=Daliy%20deal&category_id=66b7361d04044857c3f2b2b9&page=1"
                    : "/register"
                }
                className={
                  userIsLoggedIn
                    ? "bg-primary px-[1.2rem] sm:text-[10px] text-xs text-white text-center py-[0.4rem]  sm:py-[0.3rem] rounded-lg block"
                    : "bg-primary px-[1.2rem] sm:text-[10px] text-xs text-white text-center py-[0.6rem] sm:py-[0.2rem] sm:px-[0.7rem] rounded-lg"
                }
              >
                {userIsLoggedIn ? "Checkout now" : "Register Now"}
              </Link>
            </div>
            <Link
              className="rounded-lg w-[100%] sm:ml-0 mt-[0.6rem] sm:h-[100px]  inline-block sm:mt-[0rem] object-contain"
              to="https://cayroshop.com/product-list?category=Mobiles&category_id=66b1de805472ab860dac9d1c&page=1"
            >
              <LazyLoadImage
                effect="blur"
                src={`${process.env.PUBLIC_URL}/heroSectionImage/small-banner.webp`}
                alt="mobiles"
                className="rounded-lg  w-[100%] rounded-lg  sm:ml-0  sm:h-[100px] sm:mt-[0rem] object-contain"
              />
            </Link>
          </div>
        </div>

        {recommendedProducts?.length > 0 && (
          <ProductForSale
            products={recommendedProducts}
            setProduct={setRecommendedProducts}
            heading={"Recommended Products"}
            relatedProductPage={false}
          />
        )}

        {newProducts.length > 0 && (
          <ProductForSale
            products={newProducts}
            setProduct={setNewProducts}
            heading={"New Launches"}
            relatedProductPage={false}
          />
        )}

        <div className="max-w-[95%] sm:max-w-[95%] my-0 mx-auto z-10 relative ">
          <h2 className="font-semibold text-lg sm:text-sm z-[9999] absolute top-[-41px] sm:static sm:top-0">
            Shop By Products
          </h2>
          <button
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
            }}
            onClick={() => scrollRight(subCategoryRef)}
            className="absolute right-[4px] sm:hidden text-[2rem] bg-white h-[70px] flex justify-center items-center w-[50px]  top-[50%] text-black transform translate-y-[-50%] z-10 opacity-[0.6]"
            aria-label="Scroll Right"
          >
            <MdOutlineKeyboardArrowRight />
          </button>
          <ul
            ref={subCategoryRef}
            className="categories-on-home  overflow-y-scroll no-scrollbar"
            style={{
              justifyContent: "flex-start",
              marginBottom: "1rem",
            }}
          >
            {subCategories.length > 0 &&
              subCategories.map((subCat: any, index: number) => {
                return (
                  <li className="category flex justify-center ">
                    <Link
                      to={`product-list?sub-category-id=${subCat._id}&page=1`}
                      className="flex justify-center items-center flex-col"
                    >
                      <LazyLoadImage
                        effect="blur"
                        src={subCat?.image?.url}
                        // className=" w-[120px] h-[120px] object-cover bg-[white]"
                        className="category-img"
                        // style={{
                        //   width: "120px",
                        //   height: "120px",
                        // }}
                        alt="category "
                      />
                      <p className="text-[15px] sm:max-w-[70px] font-[480] mt-[0.3rem] sm:text-[11px] text-center">
                        {subCat?.name}
                      </p>
                    </Link>
                  </li>
                );
              })}
          </ul>
          <button
            onClick={() => scrollLeft(subCategoryRef)}
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
            }}
            className="absolute opacity-[0.6] left-[4px] sm:hidden text-[2rem] bg-white h-[70px] flex justify-center items-center w-[50px]  top-[50%] text-black transform translate-y-[-50%] z-10"
            aria-label="Scroll Right"
          >
            <MdOutlineKeyboardArrowLeft />
          </button>
        </div>

        {trendingProducts.length > 0 && (
          <ProductForSale
            products={trendingProducts}
            setProduct={setTrendingProducts}
            heading={"Trending Products"}
            relatedProductPage={false}
          />
        )}

        <Brand />
        {latestProducts.length > 0 && (
          <ProductForSale
            products={latestProducts}
            setProduct={setLatestProducts}
            heading={"Latest Products"}
            relatedProductPage={false}
          />
        )}
      </div>
    </div>
  );
};

export default Home;

{
  /* <div
          className="card"
          style={{
            width: "100%",
            maxWidth: "338px",
            bottom: "14px",
            marginTop: "1.3rem",
            marginRight: "2rem",
            padding: "2px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 4px",
            borderRadius: "6px",
          }}
        >
          <img 
            src="https://img.freepik.com/premium-vector/pos-payment-terminal-approved-payment-vector-stock-illustration_100456-2343.jpg?w=740"
            alt="Side Image"
            style={{ width: "100%", height: "auto", borderRadius: "4px" }}
          />
        </div> */
}
