import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addProduct } from "./CartSlice"; // Adjust the import path to your cart slice
import { RootState } from "../store/store";
import { GetRequest, PostRequest } from "../api/Request";
import { max, update } from "lodash";

interface ProductState {
  allProduct: any[]; // Specify that allProduct is an array of Product
  totalPages: number;
  page: number;
  totalItems: number;
  limitForProduct: number;
  isLoading: boolean;
  filters: any;
  selectedFilter: any;
  metaInfo: any;
}

// Define the initial state
const initialState: ProductState = {
  selectedFilter: [],
  allProduct: [],
  totalPages: 0,
  page: 1,
  totalItems: 0,
  limitForProduct: 12,
  isLoading: false,
  filters: {},
  metaInfo: {},
};

interface QueryParams {
  subCategoryIdForProduct?: string;
  categoryIdForProduct?: string;
  pageForProduct: number;
  color: any;
  brand: any;
  storage: any;
  displayQuality: any;
  kg: any;
  ltr: any;
  inch: any;
  ton: any;
  ram: any;
  star: any;
  minPrice: any;
  maxPrice: any;
}

// Create the async thunk for fetching products
export const getAllProductAccordingToCategoryOrSubCategory = createAsyncThunk(
  "product/getAllProducts",
  async (query: QueryParams, { getState, rejectWithValue }) => {
    const {
      subCategoryIdForProduct,
      categoryIdForProduct,
      pageForProduct,
      color,
      brand,
      storage,
      displayQuality,
      kg,
      ltr,
      inch,
      ton,
      ram,
      star,
      minPrice,
      maxPrice,
    } = query;

    let payload: any = {
      brand: [],
      variations: {
        Color: [],
        Storage: [],
        RAM: [],
        Star: [],
        Inch: [],
        DisplayQuality: [],
        Ltr: [],
        Kg: [],
        Ton: [],
      },
    };

    // Base URL based on the presence of subCategoryIdForProduct
    const baseUrl = subCategoryIdForProduct
      ? `searchproductbySubCategory/${subCategoryIdForProduct}`
      : `searchproductbyCategory/${categoryIdForProduct}`;

    // Construct the URL with query parameters
    const queryParams =
      maxPrice && minPrice
        ? new URLSearchParams({
            page: String(pageForProduct),
            limit: String(initialState.limitForProduct),
            maxPrice,
            minPrice,
          })
        : new URLSearchParams({
            page: String(pageForProduct),
            limit: String(initialState.limitForProduct),
          });

    // Helper function to append values to variations
    const appendToPayloadVariations = (key: string, valueArray: any[]) => {
      if (valueArray.length > 0) {
        payload.variations[key] = payload.variations[key].concat(valueArray);
      }
    };

    // Append values to payload
    if (color.length > 0) appendToPayloadVariations("Color", color);
    if (brand.length > 0) payload.brand = payload.brand.concat(brand);
    if (storage.length > 0) appendToPayloadVariations("Storage", storage);
    if (displayQuality.length > 0)
      appendToPayloadVariations("DisplayQuality", displayQuality);
    if (kg.length > 0) appendToPayloadVariations("Kg", kg);
    if (ltr.length > 0) appendToPayloadVariations("Ltr", ltr);
    if (inch.length > 0) appendToPayloadVariations("Inch", inch);
    if (ton.length > 0) appendToPayloadVariations("Ton", ton);
    if (ram.length > 0) appendToPayloadVariations("RAM", ram);
    if (star.length > 0) appendToPayloadVariations("Star", star);

    // Function to remove empty keys from the payload
    const removeEmptyKeys = (obj: any) => {
      for (const key in obj) {
        if (Array.isArray(obj[key]) && obj[key].length === 0) {
          delete obj[key];
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
          removeEmptyKeys(obj[key]); // Recursively check nested objects
          if (Object.keys(obj[key]).length === 0) {
            delete obj[key]; // Delete empty nested objects
          }
        }
      }
    };

    // Remove empty keys from the payload
    removeEmptyKeys(payload);

    // Final URL
    const url = `${baseUrl}?${queryParams.toString()}`;

    try {
      const response = await PostRequest(url, payload);

      if (response.status === 200) {
        const cartProducts = (getState() as RootState).cart.products;

        const products =
          response.data.products.docs.length > 0
            ? await response.data.products.docs.map((product: any) => {
                const cartProduct = cartProducts.find(
                  (cartItem: any) => cartItem._id === product._id
                );

                // Return the updated product
                return {
                  ...product,
                  quantityByUser: cartProduct ? cartProduct.quantityByUser : 1,
                  addToCartStatus: cartProduct ? "Update Cart" : "Add to cart", // Set status based on cart presence
                };
              })
            : [];

        return {
          products,
          totalPages: response.data.products.totalPages,
          totalItems: response.data.products.totalDocs,
          page: pageForProduct,
          metaInfo: response.data.metaInfo,
        };
      } else {
        return rejectWithValue("Failed to fetch products");
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchFiltersForCategory = createAsyncThunk(
  "product/fetchFilters",
  async (categoryId: string, { rejectWithValue }) => {
    try {
      const response = await GetRequest(
        `/get-filter-value-by-category/${categoryId}`
      );
      if (response.status === 200) {
        return response.data.filterValuesFormatted;
      } else {
        return rejectWithValue("Failed to fetch filters");
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchFiltersForSubCategory = createAsyncThunk(
  "product/fetchFiltersForSubcategory",
  async (subCategoryId: string, { rejectWithValue }) => {
    try {
      const response = await GetRequest(
        `/get-filter-value-by-subcategory/${subCategoryId}`
      );
      if (response.status === 200) {
        return response.data.filterValuesFormatted;
      } else {
        return rejectWithValue("Failed to fetch filters");
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const addToCart = createAsyncThunk(
  "product/addToCart",
  async (
    { product, index }: { product: any; index: number },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      const { allProduct } = state.product;
      const newAllProduct: any = [...allProduct];

      await dispatch(
        addProduct({
          ...product,
          total: Number(product.salePrice) * product.quantityByUser,
        })
      );

      const updatedProduct = {
        ...newAllProduct[index],
        addToCartStatus: "Update Cart",
      };

      return { index, updatedProduct };
    } catch (error) {
      console.error("Error adding to cart:", error);
      return rejectWithValue("Failed to add product to cart");
    }
  }
);

// Create a new action for incrementing the quantity
export const incrementQuantity = createAsyncThunk(
  "product/incrementQuantity",
  async (index: number, { getState }) => {
    const state = getState() as RootState;
    const { allProduct } = state.product;
    const newAllProduct: any = [...allProduct];

    const updatedProduct = {
      ...newAllProduct[index],
      quantityByUser:
        newAllProduct[index].quantityByUser !== newAllProduct[index].totalStock
          ? newAllProduct[index].quantityByUser + 1
          : newAllProduct[index].quantityByUser,
    };

    return { index, updatedProduct };
  }
);

// Create a new action for incrementing the quantity
export const decrementQuantity = createAsyncThunk(
  "product/incrementQuantity",
  async (index: number, { getState }) => {
    const state = getState() as RootState;
    const { allProduct } = state.product;
    const newAllProduct: any = [...allProduct];

    const updatedProduct = {
      ...newAllProduct[index],
      quantityByUser:
        newAllProduct[index].quantityByUser > 1
          ? newAllProduct[index].quantityByUser - 1
          : newAllProduct[index].quantityByUser,
    };

    return { index, updatedProduct };
  }
);

// Create the slice
const allProductSlice = createSlice({
  name: "productAccordingToCategoryOrSubCategory",
  initialState,
  reducers: {
    updateSelectedFilter: (state, action) => {
      const { filterField, filterValue } = action.payload;

      // Find the existing filter object for the given filterField
      const existingFilter = state.selectedFilter.find(
        (filter: any) => filter[filterField]
      );

      if (existingFilter) {
        // If the filterField exists, check if the filterValue is already present
        const valueIndex = existingFilter[filterField].indexOf(filterValue);

        if (valueIndex > -1) {
          // If it exists, remove the filterValue
          existingFilter[filterField].splice(valueIndex, 1);

          // If the array is empty after removal, remove the filterField object
          if (existingFilter[filterField].length === 0) {
            state.selectedFilter = state.selectedFilter.filter(
              (filter: any) => filter !== existingFilter
            );
          }
        } else {
          // If it doesn't exist, add the filterValue
          existingFilter[filterField].push(filterValue);
        }
      } else {
        // If it doesn't exist, create a new filter entry
        state.selectedFilter.push({ [filterField]: [filterValue] });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllProductAccordingToCategoryOrSubCategory.pending,
        (state) => {
          state.isLoading = true;
        }
      )
      .addCase(
        getAllProductAccordingToCategoryOrSubCategory.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.allProduct = action.payload.products;
          state.totalPages = action.payload.totalPages;
          state.totalItems = action.payload.totalItems;
          state.page = action.payload.page;
          state.metaInfo = action.payload.metaInfo;
        }
      )
      .addCase(
        getAllProductAccordingToCategoryOrSubCategory.rejected,
        (state) => {
          state.isLoading = false;
        }
      )
      .addCase(addToCart.fulfilled, (state, action) => {
        const { index, updatedProduct } = action.payload;
        state.allProduct[index] = updatedProduct;
      })
      .addCase(incrementQuantity.fulfilled, (state, action) => {
        const { index, updatedProduct } = action.payload;
        state.allProduct[index] = { ...updatedProduct };
      })
      .addCase(fetchFiltersForCategory.fulfilled, (state, action) => {
        state.filters = action.payload; // Update filters for category
      })
      .addCase(fetchFiltersForCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFiltersForCategory.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchFiltersForSubCategory.fulfilled, (state, action) => {
        // Ensure this action does not conflict with the category filters
        state.filters = action.payload; // Update filters for sub-category
      })
      .addCase(fetchFiltersForSubCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFiltersForSubCategory.rejected, (state) => {
        state.isLoading = false;
      });
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(
  //       getAllProductAccordingToCategoryOrSubCategory.pending,
  //       (state) => {
  //         state.isLoading = true;
  //       }
  //     )
  //     .addCase(
  //       getAllProductAccordingToCategoryOrSubCategory.fulfilled,
  //       (state, action) => {
  //         state.isLoading = false;
  //         state.allProduct = action.payload.products;
  //         state.totalPages = action.payload.totalPages;
  //         state.totalItems = action.payload.totalItems;
  //         state.page = action.payload.page;
  //       }
  //     )
  //     .addCase(
  //       getAllProductAccordingToCategoryOrSubCategory.rejected,
  //       (state) => {
  //         state.isLoading = false;
  //       }
  //     )
  //     .addCase(addToCart.fulfilled, (state: any, action) => {
  //       // Update the specific product in the state
  //       const { index, updatedProduct } = action.payload;
  //       state.allProduct[index] = updatedProduct; // Update the product at the given index
  //     })
  //     .addCase(incrementQuantity.fulfilled, (state, action) => {
  //       const { index, updatedProduct } = action.payload;
  //       state.allProduct[index] = { ...updatedProduct }; // Update the product quantity in the state
  //     })
  //     .addCase(fetchFiltersForCategory.fulfilled, (state, action) => {
  //       state.filters = action.payload; // Update the filters state with the fetched values
  //     })
  //     .addCase(fetchFiltersForCategory.pending, (state) => {
  //       state.isLoading = true; // Set loading to true
  //     })
  //     .addCase(fetchFiltersForCategory.rejected, (state, action) => {
  //       state.isLoading = false; // Set loading to false
  //     })
  //     .addCase(fetchFiltersForSubCategory.fulfilled, (state, action) => {
  //       state.filters = action.payload; // Update the filters state with the fetched values
  //     })
  //     .addCase(fetchFiltersForSubCategory.pending, (state) => {
  //       state.isLoading = true; // Set loading to true
  //     })
  //     .addCase(fetchFiltersForSubCategory.rejected, (state, action) => {
  //       state.isLoading = false; // Set loading to false
  //     });
  // },
});

// Export the reducer
export default allProductSlice.reducer;

export const { updateSelectedFilter } = allProductSlice.actions;

export const filters = (state: RootState) => state.product.filters;

export const metaInfo = (state: RootState) => state.product.metaInfo;

export const selectedFilter = (state: RootState) =>
  state.product.selectedFilter;

export const totalItemForProduct = (state: RootState) => {
  return state.product.totalItems;
};
export const totalPageForProduct = (state: RootState) => {
  return state.product.totalPages;
};
export const product = (state: RootState) => {
  return state.product.allProduct;
};

export const isLoadingInProduct = (state: RootState) => {
  return state.product.isLoading;
};

export const page = (state: RootState) => {
  return state.product.page;
};

export const isLoadingForProduct = (state: RootState) => {
  return state.product.isLoading;
};
