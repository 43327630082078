import React from "react";
import { Link } from "react-router-dom";

const Contact: React.FC = () => {
  return (
    <div className="px-8 sm:px-2  mt-[7rem] ">
      <main className="container mx-auto py-12 md:py-0 sm:px-0 px-4 flex flex-row md:flex-col lg:justify-between items-start lg:items-center">
        <div>
          <h1 className="text-4xl sm:text-2xl font-bold text-gray-800">
            Contact Us
          </h1>
          {/* <p className="mt-4 text-gray-600">
            Email, call, or complete the form to learn how cayro can solve your
            messaging problem.
          </p> */}
          <p className="mt-4 text-gray-600">
            <strong>Email:</strong> info@cayroshop.com
          </p>
          <p className="mt-2 text-gray-600">
            <strong>Phone:</strong> 9599383349
          </p>
          {/* <Link to="/" className="mt-2 text-blue-500">
            Customer Support
          </Link> */}

          <div className="mt-8 space-y-8">
            <div>
              <h2 className="text-xl font-bold text-gray-800">
                Customer Support
              </h2>
              <p className="mt-2 text-gray-600">
                Our support team is available around the clock to address any
                concerns or queries you may have.
              </p>
            </div>
            <div>
              <h2 className="text-xl font-bold text-gray-800">
                Feedback and Suggestions
              </h2>
              <p className="mt-2 text-gray-600">
                We value your feedback and are continuously working to improve
                cayro. Your input is crucial in shaping the future of cayro.
              </p>
            </div>
            <div>
              <h2 className="text-xl font-bold text-gray-800">
                Media Inquiries
              </h2>
              <p className="mt-2 text-gray-600">
                For media-related questions or press inquiries, please contact
                us at info@cayroshop.com.
              </p>
            </div>
          </div>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.512084316867!2d76.93374297549245!3d28.403801275790432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d3da4ad9f6867%3A0xb22ebae52787a4eb!2sCayroShop!5e0!3m2!1sen!2sin!4v1726307977682!5m2!1sen!2sin"
          className="rounded-lg w-[500px] h-[500px] sm:w-[400px] sm:mt-[1.3rem] h-[250px]"
          allowFullScreen={true}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        {/* <div className="w-1/3  md:w-full mx-auto bg-white p-6 md:p-5 rounded-lg shadow-md m-6 md:mx-8  sm:flex-col md:flex-col md:px-3 ">
          <h1 className="text-4xl font-bold text-gray-800">Get in Touch</h1>
          <p className="mt-2 text-gray-600">You can reach us anytime.</p>
          <form className="mt-4">
            <div className="flex flex-col md:flex-row md:space-x-4">
              <input
                type="text"
                placeholder="Full Name"
                className="w-full p-3 mt-4 md:mt-0 border border-gray-300 rounded-full"
              />
              <input
                type="email"
                placeholder="Your email"
                className="w-full p-3 mt-4 md:mt-0 border border-gray-300 rounded-full"
              />
            </div>
            <div className="flex mt-4 border border-gray-300 rounded-full">
              <select className="w-1/6 p-2 rounded-l-full">
                <option value="+91">+91</option>
              </select>
              <input
                type="tel"
                placeholder="Phone number"
                className="w-5/6 p-3 rounded-r-full"
              />
            </div>
            <textarea
              placeholder="How can we help?"
              className="w-full h-32 p-3 mt-4 border border-gray-300 rounded-lg"
            ></textarea>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white p-3 mt-4 rounded-full"
            >
              Submit
            </button>
          </form>
        </div> */}
      </main>
    </div>
  );
};

export default Contact;
