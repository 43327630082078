import { RxCross1 } from "react-icons/rx";
import Loader from "../../../common/loader/Loader";

export const MobileOtp = (props: any) => {
  let {
    activeOTPIndex,
    inputRef,
    phoneOTPHandleOnKeyDown,
    phoneOTP,
    timer,
    showTimer,
    sendOtpOnPhone,
    formatTime,
    verifyPhone,
    isLoadingForVerfiyingOtp,
    handleClosePhoneModal,
  } = props;

  return (
    <main className="fixed w-[100vw]   h-[100vh] z-[99] top-0 flex flex-col justify-center bg-slate-50 bg-opacity-90 backdrop-blur-xs overflow-hidden">
      <div className="mx-auto my-0 sm:max-w-[90%]  basis-[50%] relative h-[300px] text-center bg-white  px-4 py-10 sm:py-5 sm:px-2 rounded-xl shadow">
        <p
          onClick={handleClosePhoneModal}
          className="absolute hover:text-[black] top-[20px]  sm:top-[8px] sm:right-[8px] text-red-500 sm:text-sm cursor-pointer right-[20px]"
        >
          <RxCross1 />
        </p>
        <header className="mb-8">
          <h1 className="text-2xl font-semibold sm:text-sm mb-1">
            Mobile Number Verification
          </h1>
          <p className="text-base sm:text-xs text-slate-500">
            Enter the 6-digit verification code that was sent to your number.
          </p>
        </header>
        <div>
          <div className="flex items-center justify-center gap-2 sm:gap-[0.27rem]">
            <input
              onChange={phoneOTPHandleOnKeyDown}
              value={phoneOTP}
              type="text"
              className="w-[200px] h-12  sm:h-10 text-center font-semibold text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-2 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
              maxLength={6}
            />
          </div>
          <div className="max-w-[260px] sm:max-w-[50%]  mx-auto mt-4 sm:mt-[1.5rem]">
            <button
              type="submit"
              onClick={verifyPhone}
              className="w-full relative h-[2.5rem] inline-flex sm:text-xs justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-4 sm:px-2  py-2 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-300 transition-colors duration-150"
            >
              {isLoadingForVerfiyingOtp ? (
                <div className="absolute top-[-37%]  sm:right-[62%] sm:top-[-31%] right-[57%]">
                  <Loader />
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
        <div className="text-sm sm:text-xs text-slate-500 mt-4">
          Didn't receive code?{" "}
          {/* <p className="font-medium text-indigo-500 hover:text-indigo-600 cursor-pointer">
            Resend
          </p> */}
          <div className="flex justify-center items-center mt-[1rem] px-6">
            {showTimer && (
              <span className="text-xs font-bold text-center mr-2 text-sm text-[#f55a01]">
                Resend OTP in {formatTime(timer)}
              </span>
            )}
            {!showTimer && (
              <button
                onClick={sendOtpOnPhone}
                className="text-[#f55a01] text-center font-bold text-xs focus:outline-none hover:underline"
              >
                Resend OTP
              </button>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default MobileOtp;
