import { Link, useNavigate } from "react-router-dom";

import { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { IsLoggedIn, logout, UserInfo } from "../../slice/UserSlice";
import { IoSettingsOutline } from "react-icons/io5";
import { PostRequest } from "../../api/Request";
import { showErrorToast, showSuccessToast } from "../../utils/toast";
import { AppDispatch } from "../../store/store";
import { MdKeyboardArrowRight } from "react-icons/md";

const Sidebar = ({
  setShowSideBarInMobile,
  showSideBarInMobile,
  allCategory,
}: any) => {
  const showSideBarInMobileRef = useRef<HTMLDivElement>(null);
  const userDetail = useSelector(UserInfo);
  const isLoggedIn = useSelector(IsLoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const handleClickOutside = (event: MouseEvent) => {
    if (
      showSideBarInMobileRef.current &&
      !showSideBarInMobileRef.current.contains(event.target as Node)
    ) {
      setShowSideBarInMobile(false);
    }
  };

  const handleLinkClick = () => {
    setShowSideBarInMobile(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = async () => {
    try {
      let response = await PostRequest("/user-Logout");
      if (response.status === 200) {
        localStorage.removeItem("userToken");
        navigate("/login");
        dispatch(logout());
        handleLinkClick();
        showSuccessToast("Logged out successfully");
      }
    } catch (error: any) {
      showErrorToast(error.response.data.message);
    }
  };

  return (
    <div
      ref={showSideBarInMobileRef}
      id="drawer-body-scrolling"
      className="absolute w-full delay-300 overflow-y-scroll no-scrollbar left-[0px] top-[0px] z-40 h-screen p-4 overflow-y-auto bg-white max-w-[240px]"
      style={{
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
      }}
      aria-labelledby="drawer-body-scrolling-label"
    >
      {/* <h5
        id="drawer-body-scrolling-label"
        className="text-sm font-bold text-black capitalize flex mb-2"
      >
        <SlUser className="h-5 w-5" /> &nbsp;&nbsp; Hello,{" "}
        {userDetail.name ? (
          userDetail.name
        ) : (
          <Link to="/register" onClick={() => setShowSideBarInMobile(false)}>
            {" "}
            Sign In
          </Link>
        )}
      </h5> */}
      {/* <hr /> */}
      <button
        type="button"
        data-drawer-hide="drawer-body-scrolling"
        aria-controls="drawer-body-scrolling"
        onClick={() => setShowSideBarInMobile(false)}
        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
      >
        <svg
          className="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
        <span className="sr-only text-black font-bold">Close menu</span>
      </button>

      <div className="pb-4 overflow-y-auto">
        <ul className="space-y-2 font-medium no-scrollbar">
          <h2 className="block text-black text-sm font-semibold">
            All Categories
          </h2>
          <hr />
          {allCategory.map((category: any) => {
            return (
              <li className="text-[#1d2128] text-[0.85rem] p-1">
                <Link
                  to={`product-list?category=${category.category}&category_id=${category._id}&page=1`}
                  onClick={handleLinkClick} // Close sidebar on link click
                  className="flex items-center mb-[0.5rem] text-blue-900 rounded-lg"
                >
                  <span className="flex-1  whitespace-nowrap">
                    {category.category}{" "}
                    <MdKeyboardArrowRight className="inline" />
                  </span>
                </Link>
              </li>
            );
          })}
          {/* <hr /> */}
          {/* {isLoggedIn && (
            <li className="text-[#1d2128] text-[0.85rem] p-1">
              <Link
                to="/profile"
                onClick={handleLinkClick} // Close sidebar on link click
                className="flex items-center mb-[0.5rem] text-gray-900 rounded-lg  group"
              >
                <IoSettingsOutline className="flex-shrink-0 w-5 h-5 text-primary transition duration-75 text-primary group-hover:text-gray-900" />

                <span className="flex-1 ms-3 whitespace-nowrap">Profile</span>
              </Link>
            </li>
          )} */}
          {/* {isLoggedIn && (
            <li className="text-[#1d2128] text-[0.85rem] p-1">
              <Link
                to="/list-of-address"
                onClick={handleLinkClick} // Close sidebar on link click
                className="flex items-center mb-[0.5rem] text-gray-900 rounded-lg  group"
              >
                <FaLocationArrow className="flex-shrink-0 w-5 h-5 text-primary transition duration-75 text-primary group-hover:text-gray-900" />

                <span className="flex-1 ms-3 whitespace-nowrap">
                  Manage Address
                </span>
              </Link>
            </li>
          )} */}

          {/* {isLoggedIn && (
            <li className="text-[#1d2128] text-[0.85rem] p-1">
              <Link
                to="/order-info"
                onClick={handleLinkClick} // Close sidebar on link click
                className="flex items-center mb-[0.5rem] text-gray-900 rounded-lg  group"
              >
                <FaFirstOrder className="flex-shrink-0 w-5 h-5 text-primary transition duration-75 text-primary group-hover:text-gray-900" />

                <span className="flex-1 ms-3 whitespace-nowrap">
                  Your Orders
                </span>
              </Link>
            </li>
          )} */}
          {/* {!isLoggedIn && (
            <li className="text-[#1d2128] text-[0.85rem] p-1">
              <Link
                to="/register"
                onClick={handleLinkClick} // Close sidebar on link click
                className="flex items-center mb-[0.5rem] text-gray-900 rounded-lg  group"
              >
                <svg
                  className="flex-shrink-0 w-5 h-5 text-primary transition duration-75 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"
                  />
                </svg>
                <span className="flex-1 ms-3 whitespace-nowrap">Sign In</span>
              </Link>
            </li>
          )}
          {!isLoggedIn && (
            <li className="text-[#1d2128] text-[0.85rem] p-1">
              <Link
                to="/login"
                onClick={handleLinkClick} // Close sidebar on link click
                className="flex items-center mb-[0.5rem] text-gray-900 rounded-lg  "
              >
                <svg
                  className="flex-shrink-0 w-5 h-5 text-primary transition duration-75 text-primary group-hover:text-gray-900 dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z" />
                  <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z" />
                  <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z" />
                </svg>
                <span className="flex-1 ms-3 whitespace-nowrap">Sign Up</span>
              </Link>
            </li>
          )} */}

          {/* <li className="text-[#1d2128] text-[0.85rem] p-1">
            <Link
              to="/wishlist"
              onClick={handleLinkClick} // Close sidebar on link click
              className="flex items-center mb-[0.5rem] text-gray-900 rounded-lg  "
            >
              <FaRegHeart className="flex-shrink-0 w-5 h-5 text-primary transition duration-75 text-primary group-hover:text-gray-900 dark:group-hover:text-white" />
              <span className="flex-1 ms-3 whitespace-nowrap">Wishlist</span>
            </Link>
          </li> */}
          {/* <li className="text-[#1d2128] text-[0.85rem] p-1">
            <Link
              to="/cart"
              onClick={handleLinkClick} // Close sidebar on link click
              className="flex items-center mb-[0.5rem] text-gray-900 rounded-lg  "
            >
              <FaCartShopping className="flex-shrink-0 w-5 h-5 text-primary transition duration-75 text-primary group-hover:text-gray-900 dark:group-hover:text-white" />
              <span className="flex-1 ms-3 whitespace-nowrap">Cart</span>
            </Link>
          </li> */}
          {/* {isLoggedIn && (
            <li className="text-[#1d2128] text-[0.85rem] p-1">
              <p
                onClick={handleLogout} // Close sidebar on link click
                className="flex items-center mb-[0.5rem] text-gray-900 rounded-lg  group"
              >
                <svg
                  className="flex-shrink-0 w-5 h-5 text-primary transition duration-75 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"
                  />
                </svg>
                <span className="flex-1 ms-3 whitespace-nowrap">Logout</span>
              </p>
            </li>
          )} */}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
