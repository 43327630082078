import axios from "axios";
import { useDispatch } from "react-redux";
import { axiosRequest } from "../api/Axios";
import { useLocation } from "react-router-dom";

const axiosInstance = axios.create();

// axiosInstance.interceptors.request.use(
//   async (config) => {
//     const token = localStorage.getItem("userToken");

//     // If token exists, set it in the headers
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     } else {
//       // If token does not exist, attempt to refresh it
//       try {
//         const refreshTokenResponse = await axiosRequest.get(
//           "/refresh-access-token"
//         );
//         const newToken = refreshTokenResponse.data.newAccessToken; // Adjust based on your API response
//         localStorage.setItem("userToken", newToken);
//         config.headers.Authorization = `Bearer ${newToken}`;
//       } catch (err) {
//         // Handle refresh token failure (e.g., log out the user)
//         // dispatch(logOutAsyncThunk(''));
//        
//         // throw new Error("Unable to refresh token");
//       }
//     }

//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// const axiosPrivate = async (config: any) => {
//   try {
//     const response = await axiosRequest(config);
//     return response;
//   } catch (error: any) {
//     if (error.response && error.response.status === 401) {
//       // Handle unauthorized access
//       // dispatch(logOutAsyncThunk(''));
//     }
//     throw error;
//   }
// };

// const axiosPrivate = async (config: any) => {
//   try {
//     let token: string = localStorage.getItem("userToken") || "";

//     if (!token) {
//       // If token does not exist, attempt to refresh it
//       try {
//         const refreshTokenResponse = await axiosRequest.get(
//           "/refresh-access-token"
//         );
//         token = refreshTokenResponse.data.newAccessToken; // Adjust based on your API response
//         localStorage.setItem("userToken", token);
//       } catch (err) {
//        
//         // Handle refresh token failure (e.g., log out the user)
//         // dispatch(logOutAsyncThunk(''));
//         throw err;
//       }
//     }

//     // Attach the token to the headers
//     if (token) {
//       config.headers = {
//         ...config.headers,
//         Authorization: `Bearer ${token}`,
//       };
//     }

//     // Make the actual request
//     const response = await axiosRequest(config);
//     return response;
//   } catch (error: any) {
//     if (error.response && error.response.status === 401) {
//       // Handle unauthorized access (e.g., log out the user)
//       // dispatch(logOutAsyncThunk(''));
//     }
//     throw error;
//   }
// };

const axiosPrivate = async (config: any) => {
  try {
    let token: string = localStorage.getItem("userToken") || "";

    // Attach the token to the headers
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `${token}`,
      };
    }

    // Make the actual request
    const response = await axiosRequest(config);
    return response;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      // Handle unauthorized access (e.g., log out the user)
      // dispatch(logOutAsyncThunk(''));
    }
    throw error;
  }
};

// const axiosPrivate = async (config: any) => {
//   try {
//     const skipTokenRefresh = ["/login", "/register", "/"]; // Paths to skip token refresh
//     const currentPath = window.location.pathname;

//     // Retrieve token and expiry time
//     let token: any | null = localStorage.getItem("userToken");
//     let tokenExpiry: number = Number(localStorage.getItem("tokenExpiry")) || 0;

//     // Check if the token is missing or expired
//     if (!token || Date.now() >= tokenExpiry) {
//       if (!skipTokenRefresh.includes(currentPath)) {
//         // If token does not exist or is expired, attempt to refresh it
//         try {
//           const refreshTokenResponse = await axiosRequest.get(
//             "/refresh-access-token"
//           );
//           token = refreshTokenResponse.data.newAccessToken; // Adjust based on your API response
//           const expiresIn = refreshTokenResponse.data.expiresIn; // Adjust based on your API response
//           const newExpiry = Date.now() + expiresIn * 1000; // Calculate new expiry time

//           // Store new token and expiry time
//           localStorage.setItem("userToken", token);
//           localStorage.setItem("tokenExpiry", newExpiry.toString());
//         } catch (err) {
//          
//           // Handle refresh token failure (e.g., log out the user)
//           // dispatch(logOutAsyncThunk(''));
//           throw err;
//         }
//       } else {
//         throw new Error("Token required for this request");
//       }
//     }

//     // Attach the token to the headers
//     if (token) {
//       config.headers = {
//         ...config.headers,
//         Authorization: `Bearer ${token}`,
//       };
//     }

//     // Make the actual request
//     const response = await axiosRequest(config);
//     return response;
//   } catch (error: any) {
//     if (error.response && error.response.status === 401) {
//       // Handle unauthorized access (e.g., log out the user)
//       // dispatch(logOutAsyncThunk(''));
//     }
//     throw error;
//   }
// };

export const GetRequest = async (URL: string) => {
  return axiosPrivate({
    method: "get",
    url: URL,
  });
};

export const PostRequest = async (URL: string, data = {}) => {
  return axiosPrivate({
    method: "post",
    url: URL,
    data: data,
  });
};

export const PutRequest = async (URL: string, data = {}) => {
  return axiosPrivate({
    method: "put",
    url: URL,
    data: data,
  });
};

export const PatchRequest = async (URL: string, data = {}) => {
  return axiosPrivate({
    method: "patch",
    url: URL,
    data: data,
  });
};

export const DeleteRequest = async (URL: string) => {
  return axiosPrivate({
    method: "delete",
    url: URL,
  });
};
