import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { showSuccessToast } from "../utils/toast";
import { RootState } from "../store/store";
import { product } from "./ProductSlice";

interface Product {
  id: number;
  name: string;
  price: number;
  quantity: number;
  image: string;
  store: string;
  subtotal: number;
}

interface CartState {
  items: [];
  products: any;
  subtotal: number;
  discount: number;
  shippingCharges: number;
  total: number;
  totalTax: number;
  flatTenPercentOff: number;
}

const initialState: any = {
  products: [],
  subtotal: 0,
  discount: 0,
  totalTax: 0,
  flatTenPercentOff: 0,
  shippingCharges: 150,
  total: 0,
};

const calculateTotal = (state: any) => {
  // Ensure subtotal is a number and has a default value
  const subtotal = state.subtotal || 0;
  const totalTax = state.totalTax || 0;

  // Check if subtotal exceeds 1 lakh
  if (subtotal > 100000) {
    const discountAmount = subtotal * 0.1; // Calculate 10% discount
    state.flatTenPercentOff = discountAmount; // Store discount amount
    state.subtotal -= discountAmount; // Apply discount to subtotal
  } else {
    state.flatTenPercentOff = 0; // Reset discount if not applicable
  }

  // Calculate total including tax and ensure it is a valid number
  state.total = Math.round(subtotal + totalTax - state.flatTenPercentOff);
};

// Helper function to update subtotal based on products in the cart
const updateSubtotal = (state: any) => {
  let subtotal = 0;
  let totalTax = 0;

  // Iterate through each product to calculate subtotal and total tax
  state.products.forEach((product: any) => {
    const basePrice = Number(product.total) || 0; // Base price of the product
    const gstRate = product.gst || 0; // GST rate (default to 0 if undefined)

    // Calculate GST for the product
    const gstAmount = (basePrice * gstRate) / (100 + gstRate) || 0; // Default to 0 if NaN

    // Update subtotal and total tax
    subtotal += basePrice - gstAmount;
    totalTax += gstAmount;
  });

  console.log(subtotal, "$$$ this is subtotal");

  // Update state with calculated values ensuring they are numbers
  state.subtotal = subtotal || 0;
  state.totalTax = totalTax || 0;
};

// Create the cart slice with reducers
const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addProduct: (state, action: PayloadAction<any>) => {
      const existingProduct = state.products.find(
        (product: any) => product._id === action.payload._id
      );

      if (existingProduct) {
        existingProduct.quantityByUser += action.payload.quantityByUser;
        existingProduct.total =
          existingProduct.quantityByUser * Number(existingProduct.salePrice);
        showSuccessToast("Product updated in cart");
      } else {
        state.products.push({
          ...action.payload,
          total: action.payload.quantityByUser * action.payload.salePrice,
        });
        showSuccessToast("Product added to cart");
      }

      updateSubtotal(state);
      calculateTotal(state);

      localStorage.setItem("cart", JSON.stringify(state.products));
      localStorage.setItem("subtotal", JSON.stringify(state.subtotal));
    },

    updateQuantity: (
      state,
      action: PayloadAction<{ id: string; amount: string }>
    ) => {
      const product = state.products.find(
        (product: any) => product._id === action.payload.id
      );

      if (product) {
        // Update the quantity based on the action amount
        if (action.payload.amount === "+1") {
          product.quantityByUser++;
        } else if (
          action.payload.amount === "-1" &&
          product.quantityByUser > 1
        ) {
          product.quantityByUser--;
        }

        // Update the total price for the product
        product.total = product.quantityByUser * product.salePrice;

        // Update subtotal and calculate totals
        updateSubtotal(state);
        calculateTotal(state); // Ensure totals are recalculated

        // Update local storage
        localStorage.setItem("cart", JSON.stringify(state.products));
        localStorage.setItem("subtotal", JSON.stringify(state.subtotal));
      }
    },

    removeProduct: (state, action: PayloadAction<string>) => {
      const index = state.products.findIndex(
        (product: any) => product._id === action.payload
      );

      if (index !== -1) {
        state.products.splice(index, 1); // Remove the product

        // Update subtotal and calculate totals
        updateSubtotal(state);
        calculateTotal(state); // Ensure totals are recalculated

        // Update local storage
        localStorage.setItem("cart", JSON.stringify(state.products));
        localStorage.setItem("subtotal", JSON.stringify(state.subtotal));
      }
    },

    clearCart: (state) => {
      state.products = [];
      state.subtotal = 0;
      state.flatTenPercentOff = 0; // Reset flat discount when clearing cart

      // Calculate totals after clearing cart
      calculateTotal(state); // Ensure totals are recalculated

      localStorage.removeItem("cart");
    },

    toggleShippingCalculator: (state) => {
      state.shippingCharges = state.shippingCharges === 150 ? 0 : 150;
    },

    setCart: (state) => {
      const storedCart = localStorage.getItem("cart");
      const storedSubtotal = localStorage.getItem("subtotal");

      if (storedCart) {
        try {
          const cartFromStorage = JSON.parse(storedCart);
          state.products = cartFromStorage || [];
          updateSubtotal(state);
          calculateTotal(state); // Ensure totals are recalculated after loading from storage
        } catch (error) {
          console.error("Failed to parse cart from localStorage", error);
          state.products = [];
        }
      }

      if (storedSubtotal) {
        try {
          state.subtotal = JSON.parse(storedSubtotal) || 0;
          calculateTotal(state); // Ensure totals are recalculated after loading from storage
        } catch (error) {
          console.error("Failed to parse subtotal from localStorage", error);
          state.subtotal = 0;
        }
      }
    },

    // Additional reducers can be added here as needed...
  },
});

// const calculateTotal = (state: any) => {
//   // Check if subtotal exceeds 1 lakh
//   if (state.subtotal > 100000) {
//     const discountAmount = state.subtotal * 0.1; // Calculate 10% discount
//     state.flatTenPercentOff = discountAmount; // Store discount amount
//     state.subTotal -= discountAmount; // Apply discount to total
//   } else {
//     state.flatTenPercentOff = 0; // Reset discount if not applicable
//   }

//   // Include shipping charges in the total calculation
//   state.total = Math.round(
//     state.subtotal - state.flatTenPercentOff + state.totalTax
//   ); // Add shipping charges to total
// };

// const cartSlice = createSlice({
//   name: "cart",
//   initialState,
//   reducers: {
//     addProduct: (state, action: PayloadAction<any>) => {
//       const existingProduct = state.products.find(
//         (product: any) => product._id === action.payload._id
//       );

//       if (existingProduct) {
//         existingProduct.quantityByUser += action.payload.quantityByUser;
//         existingProduct.total =
//           existingProduct.quantityByUser * Number(existingProduct.salePrice);
//         showSuccessToast("Product updated in cart");
//       } else {
//         state.products.push({
//           ...action.payload,
//           total: action.payload.quantityByUser * action.payload.salePrice,
//         });
//         showSuccessToast("Product added to cart");
//       }

//       updateSubtotal(state);
//       calculateTotal(state);

//       localStorage.setItem("cart", JSON.stringify(state.products));
//       localStorage.setItem("subtotal", JSON.stringify(state.subtotal));
//     },

//     updateQuantity: (
//       state,
//       action: PayloadAction<{ id: string; amount: string }>
//     ) => {
//       const product = state.products.find(
//         (product: any) => product._id === action.payload.id
//       );

//       if (product) {
//         // Update the quantity based on the action amount
//         if (action.payload.amount === "+1") {
//           product.quantityByUser++;
//         } else if (
//           action.payload.amount === "-1" &&
//           product.quantityByUser > 1
//         ) {
//           product.quantityByUser--;
//         }

//         // Update the total price for the product
//         product.total = product.quantityByUser * product.salePrice;

//         // Update subtotal and calculate totals
//         updateSubtotal(state);
//         calculateTotal(state); // Ensure totals are recalculated

//         // Update local storage
//         localStorage.setItem("cart", JSON.stringify(state.products));
//         localStorage.setItem("subtotal", JSON.stringify(state.subtotal));
//       }
//     },

//     removeProduct: (state, action: PayloadAction<string>) => {
//       const index = state.products.findIndex(
//         (product: any) => product._id === action.payload
//       );

//       if (index !== -1) {
//         state.products.splice(index, 1); // Remove the product

//         // Update subtotal and calculate totals
//         updateSubtotal(state);
//         calculateTotal(state); // Ensure totals are recalculated

//         // Update local storage
//         localStorage.setItem("cart", JSON.stringify(state.products));
//         localStorage.setItem("subtotal", JSON.stringify(state.subtotal));
//       }
//     },

//     clearCart: (state) => {
//       state.products = [];
//       state.subtotal = 0;
//       state.flatTenPercentOff = 0; // Reset flat discount when clearing cart

//       // Calculate totals after clearing cart
//       calculateTotal(state); // Ensure totals are recalculated

//       localStorage.removeItem("cart");
//     },

//     toggleShippingCalculator: (state) => {
//       state.shippingCharges = state.shippingCharges === 150 ? 0 : 150;
//     },

//     setCart: (state) => {
//       const storedCart = localStorage.getItem("cart");
//       const storedSubtotal = localStorage.getItem("subtotal");

//       if (storedCart) {
//         try {
//           const cartFromStorage = JSON.parse(storedCart);
//           state.products = cartFromStorage || [];
//           updateSubtotal(state);
//           calculateTotal(state); // Ensure totals are recalculated after loading from storage
//         } catch (error) {
//           console.error("Failed to parse cart from localStorage", error);
//           state.products = [];
//         }
//       }

//       if (storedSubtotal) {
//         try {
//           state.subtotal = JSON.parse(storedSubtotal);
//           calculateTotal(state); // Ensure totals are recalculated after loading from storage
//         } catch (error) {
//           console.error("Failed to parse subtotal from localStorage", error);
//           state.subtotal = 0;
//         }
//       }
//     },
//   },
// });

// // Helper function to update subtotal based on products in the cart
// const updateSubtotal = (state: any) => {
//   // Initialize subtotal and total tax
//   let subtotal = 0;
//   let totalTax = 0;

//   // Iterate through each product to calculate subtotal and total tax
//   state.products.forEach((product: any) => {
//     const basePrice = Number(product.total); // Base price of the product
//     const gstRate = product.gst; // GST rate (18% or 28%)

//     // Calculate GST for the product

//     const gstAmount = (basePrice * gstRate) / (100 + gstRate); // Correctly calculate GST

//     // Update subtotal and total tax
//     subtotal += basePrice - gstAmount;
//     totalTax += gstAmount;
//   });
//   console.log(subtotal, "$$$ this is subtotal");
//   // Update state with calculated values
//   state.subtotal = subtotal;
//   state.totalTax = totalTax;
//   // state.total = subtotal + totalTax; // Total including tax
// };

// Export actions and reducer
export const {
  addProduct,
  updateQuantity,
  removeProduct,
  clearCart,
  toggleShippingCalculator,
  setCart,
} = cartSlice.actions;

export default cartSlice.reducer;

export const totalCartItem = (state: RootState) => {
  return state.cart.products.length;
};

export const allProduct = (state: RootState) => {
  return state.cart.products;
};
