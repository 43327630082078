import React, { useEffect, useState } from "react";
import { showErrorToast } from "../../utils/toast";
import { GetRequest } from "../../api/Request";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Brand: React.FC = () => {
  const [allBrand, setAllBrand] = useState([]);
  const [isLoadingForBrand, setIsLoadingForBrand] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const getAllBrand = async () => {
    setIsLoadingForBrand(true);
    try {
      let response = await GetRequest("all-brand");
      if (response.status === 200) {
        setAllBrand(() => response.data.brands);
      }
    } catch (error: any) {
      showErrorToast(error.response.data.message);
    }
    setIsLoadingForBrand(false);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    getAllBrand();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isHovered && allBrand.length > 0) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % allBrand.length);
      }
    }, 2000); // Change slide every 3 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [isHovered, allBrand.length]);

  return (
    <div className="max-w-[95%] sm:pt-[1rem] mx-auto my-0 overflow-x-auto pb-[2rem] sm:pb-[0rem] no-scrollbar">
      <div className="flex flex-col">
        <h2 className="font-semibold text-lg sm:text-sm">Trending Brands</h2>
        <div className="flex flex-nowrap overflow-x-auto mt-[1rem] no-scrollbar">
          {allBrand?.length > 0 &&
            allBrand?.map((brand: any, index: number) => {
              return (
                <div
                  key={index}
                  className="flex-shrink-0 flex basis-[8%] sm:basis-[18%] mx-[1.5rem] sm:mx-[0.8rem] cursor-pointer justify-center items-center bg-white"
                >
                  <LazyLoadImage
                    effect="blur"
                    src={brand.image.url}
                    alt={`Brand logo ${index + 1}`}
                    className="h-[5rem] w-auto object-contain"
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Brand;
