import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
interface InputNumberProps {
  name: string;
  placeholder: string;
  onHandleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: number | undefined;
  minLength: 10;
}

const InputNumber: React.FC<InputNumberProps> = ({
  name,
  placeholder,
  value,
  minLength,
  onHandleChange = () => {},
}) => {
  return (
    <div className="relative mt-2">
      <div className="flex bg-white space-between items-center border rounded-xl">
        <div className="flex bg-gray items-center border-1 border-slate-1 m-1 px-1">
          <LazyLoadImage
            effect="blur"
            src={"https://flagicons.lipis.dev/flags/4x3/in.svg"}
            alt={"indian-flag"}
            className="inline-block h-4 mr-1"
            // Optional effect for loading transition
          />
          +91
        </div>
        <div>
          <input
            onChange={(event) => onHandleChange(event)}
            value={value}
            minLength={minLength}
            className="p-2 outline-none  border-l-[0.5px] border-[#ddd] rounded-tr-xl rounded-br w-full"
            type="tel"
            name={name}
            placeholder={placeholder}
            required
          />
        </div>
      </div>
    </div>
  );
};

export default InputNumber;
