import { useDispatch, useSelector } from "react-redux";

import { IsLoggedIn, setUser, UserInfo } from "../../../slice/UserSlice";
import { useEffect, useRef, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { showErrorToast, showSuccessToast } from "../../../utils/toast";
import { useLocation, useNavigate } from "react-router-dom";
import { PostRequest } from "../../../api/Request";
import EmailOtp from "./verifyOtpForPhone";
import { MdOutlineVerified } from "react-icons/md";
import Loader from "../../../common/loader/Loader";

const UserDetail = (props: any) => {
  let {
    userInfo,
    setUserInfo,
    isButtonDisabled,
    isLoadingForOtp,
    handleSendOtp,
    setShippingData,
    shippingData,
    billingData,
    setBillingData,
    errorsForShipping,
    setErrorsForShipping,
    errorsForBilling,
    setErrorsForBilling,
  } = props;
  const userDetail: any = useSelector(UserInfo);
  let userIsLoggedIn = useSelector(IsLoggedIn);
  const dispatch = useDispatch();
  const [activeOTPIndex, setActiveOTPIndex] = useState<number>(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const [timer, setTimer] = useState(30);
  const [showTimer, setShowTimer] = useState(true);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const validateEmail = (email: string) => {
    // Regular expression for validating an email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setShowTimer(false);
    }
  }, [timer]);

  useEffect(() => {
    inputRef.current?.focus();
    return;
  }, [activeOTPIndex]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let errorMessage = "";
    let newShippingData = { ...shippingData };
    let newBillingData = { ...billingData };
    let newErrorForBilling = { ...errorsForBilling };
    let newErrorForShipping = { ...errorsForShipping };
    switch (name) {
      case "username":
        newShippingData.name = value;
        newBillingData.name = value;
        newErrorForBilling.name = "";
        newErrorForShipping.name = "";

        if (value.trim() === "") {
          errorMessage = "Name is required.";
        } else if (value.length < 3) {
          errorMessage = "Name must be at least 3 characters.";
        } else {
          errorMessage = "";
        }
        break;
      case "password":
        if (value.length < 6 || !/[0-9]/.test(value)) {
          errorMessage =
            "Password must be at least 6 characters and contain at least one number.";
        } else {
          errorMessage = ""; // No errors
        }
        break;
      case "mobile":
        // Regular express6ion for Indian phone numbers
        const phoneNumberPattern = /^[6789]\d{9}$/;
        newShippingData.mobileNumber = value;
        newBillingData.mobileNumber = value;
        if (value.trim() === "") {
          errorMessage = "Phone number is required.";
        } else if (value.length !== 10) {
          errorMessage = "Phone number must be of 10 digits only.";
        } else if (!phoneNumberPattern.test(value)) {
          errorMessage = "Please enter a valid phone number.";
        } else {
          errorMessage = "";
        }
        break;
      case "email":
        if (!validateEmail(value)) {
          errorMessage = "Enter a valid email";
        } else {
          errorMessage = "";
        }
        break;
      default:
        break;
    }

    setUserInfo((prevState: any) => ({
      ...prevState,
      [name]:
        name === "mobile" ? { ...prevState.mobile, number: value } : value,
      error: {
        ...prevState.error,
        [name]: errorMessage,
      },
    }));
    setErrorsForBilling(() => newErrorForBilling);
    setErrorsForShipping(() => newErrorForShipping);
    setShippingData(() => newShippingData);
    setBillingData(() => newBillingData);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <div className="space-y-4 mb-[1.5rem]">
      <h2 className="text-xl font-semibold text-gray-900">User Details</h2>
      <div className="grid grid-cols-1 gap-4">
        {!userIsLoggedIn && (
          <div>
            <label
              htmlFor="phone-input-3"
              className="mb-2 block text-sm  text-gray-900 "
            >
              Phone Number*
            </label>
            <div className="flex items-center">
              <button
                id="dropdown-phone-button-3"
                data-dropdown-toggle="dropdown-phone-3"
                className="z-10 inline-flex shrink-0 items-center rounded-s-lg border border-gray-300 bg-gray-100 px-4 py-2.5 text-center text-sm  text-gray-900"
                type="button"
              >
                +91
              </button>

              <div className="flex items-center relative w-[100%]">
                <div className="w-full">
                  <input
                    type="tel"
                    name="mobile"
                    placeholder="Your number"
                    value={userInfo.mobile.number}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded focus:bg-white"
                    disabled={userInfo.mobile.isVerified}
                  />
                </div>

                {isLoadingForOtp ? (
                  <div className="absolute right-[42px] top-[-18px] z-10">
                    <Loader />
                  </div>
                ) : (
                  <>
                    {userInfo.mobile.isVerified ? (
                      <MdOutlineVerified
                        className="absolute right-[5px] top-[10px] text-green-500"
                        title="OTP Verified"
                      />
                    ) : (
                      <p
                        className={
                          isButtonDisabled
                            ? "cursor-not-allowed text-sm absolute right-[10px] top-[10px] font-medium text-[#3f0e9e] ml-2"
                            : "text-sm absolute right-[10px] top-[10px] font-medium cursor-pointer text-[#3f0e9e] hover:underline dark:text-[#3f0e9e] ml-2"
                        }
                        onClick={!isButtonDisabled ? handleSendOtp : undefined}
                      >
                        Verify
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
            <p className="text-red-500 text-xs italic">
              {userInfo.error.number}
            </p>
          </div>
        )}

        {!userIsLoggedIn && (
          <div>
            <label
              htmlFor="your_name"
              className="mb-2 block text-sm  text-gray-900 "
            >
              Your name
            </label>
            <input
              autoComplete="off"
              type="text"
              id="your_name"
              name="username"
              className="block w-full rounded-lg border border-gray-300  p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500  dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
              placeholder="Your Name"
              value={userInfo.username}
              onChange={handleChange}
              required
            />
            <p className="text-red-500 text-xs italic">
              {userInfo.error.username}
            </p>
          </div>
        )}

        {!userIsLoggedIn || !userDetail.email ? (
          <div className="basis-[45%] sm:basis-[100%] sm:mt-[1rem]">
            <div className="mb-1 flex items-center justify-between relative">
              <label
                htmlFor="cus_email"
                className="block font-semibold  text-sm text-gray-600 mb-1"
              >
                Email
              </label>
            </div>
            <input
              type="email"
              id="cus_email"
              name="email"
              className="w-full px-4 py-2 border border-gray-300 rounded focus:bg-white"
              placeholder="Your Email"
              aria-label="Email"
              required
              onChange={handleChange}
              value={userInfo?.email}
            />
            <p className="text-red-500 text-xs italic">
              {userInfo.error.email}
            </p>
          </div>
        ) : null}

        {!userIsLoggedIn && (
          <div>
            <label className="mb-2 block text-sm  text-gray-900 ">
              Password
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  className="block w-full rounded-lg border border-gray-300  p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500  dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
                  placeholder="enter your password"
                  value={userInfo.password}
                  onChange={handleChange}
                  disabled={false}
                />
                <span
                  className="absolute top-[50%] right-3 flex items-center cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  {!showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              <p className="text-red-500 text-xs italic">
                {userInfo.error.password}
              </p>
            </label>
          </div>
        )}
      </div>
    </div>
  );
};
export default UserDetail;
