import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

const SubCategory = (props: any) => {
  const { subCategory, category } = props;

  return (
    <div className=" flex justify-end m-12 w-[290%]">
      {/* First Column - Left Column for Subcategories */}
      <ul className="col-4 sub-menu mega-menu mega-menu--grid flex flex-col w-[300px]">
        {subCategory.length > 0 &&
          subCategory.map((subCategoryName: string, index: number) => (
            <li
              key={index}
              className="mega-menu-container container full-width flex-1"
            >
              <div className="mega-menu__row">
                <ul className="mega-menu__column w-full">
                  <li className="menu-item menu-item-19455 menu-item--widget menu-item--widget-taxonomy-list">
                    <ul className="menu-taxonomy-list-widget">
                      <li
                        key={subCategoryName}
                        className="menu-item--widget menu-taxonomy-list-widget__item"
                      >
                        <Link
                          to={`product-list?sub-category-id=${subCategoryName}&page=1`}
                          className="block px-4 py-2 rounded-md text-black my-0.5 hover:underline hover:text-blue-500 hover:font-semibold flex items-center"
                        >
                          {subCategoryName}
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
          ))}
      </ul>

      {/* Third Column - Right Column for Large Image */}
      <ul className="col-6 sub-menu mega-menu mega-menu--grid flex flex-col w-full">
        {/* {subCategory.map((subCategory: string, index: number) => ( */}
        <li
          // key={index}
          className="menu-item menu-item-19457 menu-item--widget menu-item--widget-image menu-item--align-left  flex-1 w-full"
        >
          {/* <Link
            to={subCategory}
            className="block relative text-black h-full w-full"
          > */}
          {/* Image on the right side */}
          <LazyLoadImage
            effect="blur"
            src={category.image}
            className="attachment-full size-full mr-2 rounded object-cover w-[300px] h-[300px]"
            alt={category.name}
            decoding="async"
          />
          {/* </Link> */}
        </li>
        {/* ))} */}
      </ul>
    </div>
  );
};

export default SubCategory;
