import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./index.css";
import Home from "./page/main/Home";
import Login from "./page/login/Login";
import Header from "./page/header/Header";
import "react-toastify/dist/ReactToastify.css";
import Register from "./page/register/Register";
import ProductCard from "./page/productDetail/ProductCard";
import ShoppingCart from "./page/cart/Cart";
import mainLoader from "./loti/mainLoader.json";
import Review from "./page/reviews/Review";
import SubCategory from "./page/header/SubCategory";
import ProductListing from "./page/productListing/ProductListing";
import { FaFirstAid } from "react-icons/fa";
import { useMediaQuery } from "@react-hook/media-query";
import AdBanner from "./page/banners/WatchBanner";
import AdBan from "./page/banners/EarPhoneBanner";
import BigSale from "./page/banners/OrderSuccess";
import Wishlist from "./page/wishlist/Wishlist";
import Contact from "./page/contact/Contact";
import About from "./page/about/about";
import TermsAndConditions from "./page/termsAndCondition/termsAndCondition";
import ReturnAndExchange from "./page/returnAndExchange/ReturnAndExchange";
import Footer from "./page/main/Footer";
import { useEffect, useState } from "react";
import { GetRequest } from "./api/Request";
import { showErrorToast } from "./utils/toast";
import Blog from "./page/blog/Blog";
import MobileCart from "./page/cart/MobileCart";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "./slice/UserSlice";
import Lottie from "lottie-react";
import Profile from "./page/userSetting/userDetail/Profile";
import OrderList from "./page/userSetting/userDetail/orderList";
import axios, { AxiosError } from "axios";
import { UserInfo } from "./slice/UserSlice";

import AddressList from "./page/userSetting/userDetail/AddressList";
import PrivacyPolicy from "./page/privacyPolicy/PrivacyPolicy";
import ReturnRefundPolicy from "./page/returnAndRefundPolicy/ReturnAndRefundPolicy";
import ShippingDeliveryPolicy from "./page/shippingAndDelivery/ShippingAndDeliveryPolicy";
import ForgetPassword from "./page/forgotPassword/ForgetPassword";
import { Helmet } from "react-helmet";
import Checkout from "./page/checkout/Checkout";
import { setCart } from "./slice/CartSlice";
import Compare from "./common/compare/Compare";
import { compareProducts } from "./slice/CompareProductsSlice";
import ComparisonPage from "./page/compare/ComparisonPage";

function App() {
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [renderedComponent, setRenderedComponent] = useState<JSX.Element>(
    isMobile ? <MobileCart /> : <ShoppingCart />
  );
  const userDetail = useSelector(UserInfo);
  const [isLoadingForCategory, setIsLoadingForCategory] =
    useState<boolean>(true);
  const compareTheseProducts = useSelector(compareProducts);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allCategory, setAllCategory] = useState<any>([]);
  const [subCategories, setSubCategories] = useState<any>([]);
  // State to manage loading and error
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null);
  const [data, setData] = useState<any>(null);

  // useEffect(() => {
  //   axios
  //     .post(
  //       "https://service.alignbooks.com/ABReportService.svc/GetReportData",
  //       {
  //         report_type: 4145,
  //         filter_data: {
  //           item: {
  //             name: "Text_Item",
  //             applicable: true,
  //             caption: "Item",
  //             master_list_type: 1,
  //             multi_select: true,
  //             sequence: 0,
  //             value: "981b5413-46d8-49b2-9c4c-017abff0ba92",
  //           },
  //         },
  //       },
  //       {
  //         headers: {
  //           username: "info@cayroshop.com",
  //           apikey: "1ad6db42-6e35-4988-8c27-a605b2129a22",
  //           company_id: "850273ba-4648-46f4-9d09-2c2639e5cd2d",
  //           enterprise_id: "e0789919-d668-4979-9a06-5bbcd313067b",
  //           user_id: "033d6e84-6ba6-4620-b995-01d0f0430a13",
  //         },
  //       }
  //     )
  //     .then((res: any) => {
  //
  //     });
  // }, []);

  // "item": {
  // 		"name": "Text_Item",
  // 		"applicable": true,
  // 		"caption": "Item",
  // 		"master_list_type": 1,
  // 		"multi_select": true,
  // 		"sequence": 0,
  // 		"value": "981b5413-46d8-49b2-9c4c-017abff0ba92",
  // 		"parentName": "",
  // 		"parentFieldName": "",
  // 		"vType": 0
  // 	},

  // Function to fetch user profile
  const fetchUserProfile = async () => {
    try {
      const response = await GetRequest("/user-profile");
      setData(response.data);
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      fetchUserProfile();
    }
    if (localStorage.getItem("cart")) {
      dispatch(setCart());
    }
  }, []);

  // Use another useEffect to dispatch user data when data is fetched
  useEffect(() => {
    if (data) {
      dispatch(
        setUser({
          name: data?.profile?.username || "",
          number: data?.profile?.number || "",
          gender: data?.profile?.gender || "",
          email: data?.profile?.email || "",
          addresses: data?.profile.addresses || "",
          userIsLoggedIn: true,
        })
      );
    }
  }, [data, dispatch]);

  useEffect(() => {
    getAllCategory();
  }, []);

  useEffect(() => {
    setRenderedComponent(isMobile ? <MobileCart /> : <ShoppingCart />);
  }, [isMobile]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const getAllCategory = async () => {
    setIsLoadingForCategory(() => true);
    try {
      let response = await GetRequest("/getAllproduct-categories");
      if (response.status === 200) {
        setAllCategory(() => response.data);
        setSubCategories((prevState: any) => {
          return response.data.flatMap(
            (category: any) => category.subCategories
          );
        });
      }
    } catch (error: any) {
      showErrorToast(error?.response?.data?.message);
    }
    setIsLoadingForCategory(() => false);
  };

  useEffect(() => {
    if (location.pathname.includes("home")) {
      navigate("/");
    }
  }, []);

  return loading ? (
    <div className="h-[100vh] w-[100vw] flex justify-center items-center ">
      <Helmet>
        <title>cayroshop.com</title>
        <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
      </Helmet>
      <Lottie
        animationData={mainLoader}
        loop={true}
        style={{
          width: "10rem",
          height: "10rem",
        }}
        className="w-[20rem] h-[20rem] sm:w-[12rem] sm:h-[12rem]"
      />
    </div>
  ) : (
    <>
      {compareTheseProducts?.length > 0 &&
        !location.pathname.includes("comparison") && <Compare />}
      <Header
        allCategory={allCategory}
        isLoadingForCategory={isLoadingForCategory}
      />
      <Routes>
        <Route path="/review" Component={Review} />
        <Route path="/banner" Component={AdBan} />
        <Route path="/product" Component={ProductCard} />
        {/* <Route path="/blogs" Component={Blog} /> */}
        <Route path="/review" Component={Review} />
        <Route path="/privacy-policy" Component={PrivacyPolicy} />
        <Route path="/return-and-refund" Component={ReturnRefundPolicy} />
        {/* <Route path="/comparison" Component={ComparisonPage} /> */}
        <Route
          path="/shipping-and-delivery"
          Component={ShippingDeliveryPolicy}
        />
        <Route path="/checkout" Component={Checkout} />
        <Route
          path="/"
          element={
            <Home allCategory={allCategory} subCategories={subCategories} />
          }
        />
        <Route path="/list-of-address" Component={AddressList} />
        <Route path="/login" Component={Login} />
        <Route path="/forget-password" Component={ForgetPassword} />

        <Route path="/register" Component={Register} />
        <Route path="/subcategory" Component={SubCategory} />
        <Route path="/profile" Component={Profile} />
        <Route path="/order-info" Component={OrderList} />
        <Route path="/wishlist" Component={Wishlist} />
        <Route path="/cart" element={renderedComponent} />
        <Route path="/contact" Component={Contact}></Route>
        <Route path="/about" Component={About}></Route>
        <Route
          path="/return-and-exchange"
          Component={ReturnAndExchange}
        ></Route>

        <Route
          path="/terms-and-condition"
          Component={TermsAndConditions}
        ></Route>
        <Route path="/product-list" Component={ProductListing} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
