import { useDispatch, useSelector } from "react-redux";
import AccountSidebar from "../SideBar";
import {
  IsLoading,
  IsLoggedIn,
  setUser,
  UserInfo,
} from "../../../slice/UserSlice";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import DeliveryAddress from "../../deliveryAddress/DeliveryAddress";
import DeleteDialogue from "../../deleteDialogue/DeleteDialogue";
import { DeleteRequest, PostRequest } from "../../../api/Request";
import { showErrorToast, showSuccessToast } from "../../../utils/toast";
import { AppDispatch } from "../../../store/store";

const AddressList = (props: any) => {
  let { checkoutPage, heading } = props;
  const userDetail: any = useSelector(UserInfo);
  const [showAddressForm, setShowAddAddress] = useState<Boolean>(false);
  const [isLoadingForDelete, setIsLoadingForDelete] = useState<boolean>(false);
  const [indexForDelete, setIndexForDelete] = useState<any>();
  const dispatch = useDispatch<AppDispatch>();
  const [selectedAddressIndex, setSelectedAddressIndex] = useState<any>(null);
  const userIsLoggedIn = useSelector(IsLoggedIn);
  const navigate = useNavigate();
  const isLoadingForUser = useSelector(IsLoading);

  useEffect(() => {
    if (!localStorage.getItem("userToken")) {
      navigate("/");
    }
  }, [userIsLoggedIn, isLoadingForUser]);

  useEffect(() => {
    if (selectedAddressIndex !== null) {
      localStorage.setItem(
        "selectedAddressIndex",
        selectedAddressIndex.toString()
      );
    } else {
      localStorage.removeItem("selectedAddressIndex"); // Clear if no address is selected
    }
  }, [selectedAddressIndex]);

  

  const handleSelectAddress = async (index: number, value: string) => {
    // Prepare the payload based on the selected address type
    let payload =
      value === "shipping"
        ? { shipping: true, index }
        : { billing: true, index };

    try {
      // Send the request to update the default address
      const response = await PostRequest("/update-default-address", payload);

      // Check if the response indicates success
      if (response.status === 200) {
        // Create a copy of current addresses
        const updatedAddresses: any = [...userDetail.addresses];

        // Reset all addresses to not be default for shipping or billing
        updatedAddresses.forEach((address: any, idx: any) => {
          if (value === "shipping") {
            updatedAddresses[idx] = {
              ...address,
              shipping: idx === index, // Set current index as true, others as false
            };
          } else if (value === "billing") {
            updatedAddresses[idx] = {
              ...address,
              billing: idx === index, // Set current index as true, others as false
            };
          }
        });

        // Dispatch updated user details with modified addresses
        dispatch(
          setUser({
            name: userDetail.username || "",
            number: userDetail.number || "",
            gender: userDetail.gender || "",
            email: userDetail.email || "",
            addresses: updatedAddresses || [],
            userIsLoggedIn: true,
          })
        );

        
      }
    } catch (error) {
      // Handle errors and show error toast
      showErrorToast("Failed to update address");
    }
  };

  const AddAddressForm = () => {
    setShowAddAddress(() => true);
  };

  const ShowDeleteAddress = (index: number) => {
    setIndexForDelete(() => index);
  };

  const handleDeleteAddress = async (index: number) => {
    setIsLoadingForDelete(() => true);
    try {
      let response = await DeleteRequest(`delete-address/${indexForDelete}`);
      if (response.status === 200) {
        setIndexForDelete(null); // Reset index for delete
        if (response.data.addresses.length > 0) {
          setSelectedAddressIndex(() => 0);
          localStorage.setItem("selectedAddressIndex", "0");
        }

        dispatch(
          setUser({
            name: userDetail.name || "",
            number: userDetail.number || "",
            gender: userDetail.gender || "",
            email: userDetail.email || "",
            addresses: response.data.addresses,
            userIsLoggedIn: true,
          })
        );
        showSuccessToast("Deleted Successfully");
      }
    } catch (error) {
      showErrorToast("Error in Deleting the address");
    }
    setIsLoadingForDelete(() => false);
  };

  return (
    <div
      className={
        !checkoutPage
          ? "flex mt-[8rem] items-start justify-center flex-wrap mb-[1rem] sm:px-[0.7rem]"
          : "flex  items-start justify-center flex-wrap mb-[1rem] sm:px-[0.7rem]"
      }
    >
      {!checkoutPage && (
        <div className="basis-[20%] sm:hidden lg:basis-[100%] mt-[1rem] border-r-[1px] border-r-[#ddd]">
          <AccountSidebar />
        </div>
      )}
      {showAddressForm && (
        <DeliveryAddress
          // heading={''}
          showAddressForm={showAddressForm}
          setShowAddAddress={setShowAddAddress}
        />
      )}

      {indexForDelete != null ? (
        <DeleteDialogue
          handleDeleteAddress={handleDeleteAddress}
          setIndexForDelete={setIndexForDelete}
          isLoadingForDelete={isLoadingForDelete}
        />
      ) : null}

      <div
        className={
          checkoutPage
            ? "flex-1  sm:p-0 basis-[50%] md:basis-[90%] md:mt-0 w-full"
            : "flex-1 p-4 sm:p-0 basis-[50%] md:basis-[90%] md:mt-0 w-full"
        }
      >
        <div
          className={
            !checkoutPage
              ? "w-[90%] h-full bg-white rounded p-2 mx-auto sm:w-[93%] sm:p-0"
              : " bg-white  mx-auto  "
          }
        >
          {heading !== "Billing Address" && (
            <div className="flex justify-between items-start">
              <h1 className="font-medium text-2xl sm:text-lg mb-6 sm:mb-3">
                Your Addresses
              </h1>
              <span
                onClick={AddAddressForm}
                className="bg-primary cursor-pointer text-white h-[30px] w-[30px] rounded-[50%] flex justify-center items-center text-xl"
              >
                +
              </span>
            </div>
          )}

          {userDetail?.addresses?.length > 0 && (
            <div className="mb-4">
              <h3 className="font-semibold text-[18px]">{heading}</h3>
            </div>
          )}

          <ul>
            {userDetail.addresses?.length > 0 ? (
              userDetail.addresses.map((address: any, index: number) => {
                return (
                  <div key={index}>
                    <li className="items-center">
                      {checkoutPage && (
                        <input
                          type="checkbox"
                          checked={
                            heading === "Shipping Address"
                              ? address.shipping
                              : address.billing
                          }
                          onChange={() =>
                            handleSelectAddress(
                              index,
                              heading === "Shipping Address"
                                ? "shipping"
                                : "billing"
                            )
                          } // Update selection on checkbox change
                          className="mr-2 cursor-pointer"
                        />
                      )}
                      <span>{index + 1}. </span>
                      <span className="font-semibold text-[15px]">
                        {address?.name + ", "}
                      </span>
                      <span className="font-semibold text-[15px]">
                        {address?.mobileNumber + ", "}
                      </span>
                      <span>{address?.address1 + ", "} </span>
                      <span>{address?.address2 + ", "} </span>
                      <span>{address?.city + ", "} </span>
                      <span>{address?.state + ", "} </span>
                      <span>{address?.postalCode + ", "} </span>
                      <span>{address?.country + ", "} </span>
                      {/* <p className="text-blue-500 cursor-pointer text-sm inline-block">
                        {"Edit Address" + ",  "}
                      </p> */}
                      <p
                        onClick={() => ShowDeleteAddress(index)}
                        className="text-blue-500 cursor-pointer text-sm inline-block"
                      >
                        Delete Address
                      </p>
                    </li>
                    <hr className="my-[0.9rem]" />
                  </div>
                );
              })
            ) : (
              <p className="text-center text-red-500 mt-[1rem]">
                No Addresses Found
              </p>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AddressList;
