import React from "react";
import { BsBagHeart } from "react-icons/bs";
import { MdAccountBox, MdPayment } from "react-icons/md";
import { IoIosLogOut } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { CiHeart } from "react-icons/ci";
import { IoIosGitCompare } from "react-icons/io";
import { FaHandsHelping } from "react-icons/fa";
import { PostRequest } from "../../api/Request";
import { useDispatch, useSelector } from "react-redux";
import { logout, UserInfo } from "../../slice/UserSlice";
import { showErrorToast, showSuccessToast } from "../../utils/toast";
import { AppDispatch } from "../../store/store";
import { LazyLoadImage } from "react-lazy-load-image-component";

const AccountSidebar: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const userDetail = useSelector(UserInfo);
  const handleClickOnLogout = async () => {
    try {
      let response = await PostRequest("/user-Logout");
      if (response.status === 200) {
        localStorage.removeItem("userToken");
        navigate("/login");
        dispatch(logout());
        showSuccessToast("Logged out successfully");
      }
    } catch (error: any) {
      showErrorToast(error.response.data.message);
    }
  };

  return (
    <div className="flex flex-col bg-white shadow-3xl p-4 h-full w-full  md:sticky md:top-0">
      <div className="flex flex-col items-center mb-6 sm:mb-3">
        <LazyLoadImage
          effect="blur"
          className="w-16 h-16 p-1 rounded-full ring-2 mt-[0rem] ring-gray-300 mt-6"
          src="https://cdn-icons-png.freepik.com/512/147/147140.png"
          alt="Avatar"
        />
        <div className="mt-2 text-center">
          <div className="text-xs font-medium">Hello,</div>
          <div className="text-sm font-bold">{userDetail?.name}</div>
        </div>
      </div>
      <ul className="flex flex-col space-y-4 mt-4">
        <li className="nav-item flex items-center text-black hover:text-blue-600 transition duration-200 text-[15px]">
          <CgProfile className="w-4 h-4" />
          <Link to="/profile" className="ml-2">
            My Profile
          </Link>
        </li>
        <hr className="my-2" />
        <li className="nav-item flex items-center text-black hover:text-blue-600 transition duration-200 text-[15px]">
          <BsBagHeart className="w-4 h-4" />
          <Link to="/order-info?page=1" className="ml-2">
            My Orders
          </Link>
        </li>
        <hr className="my-2" />
        <li className="nav-item flex items-center text-black hover:text-blue-600 transition duration-200 text-[15px]">
          <MdPayment className="w-4 h-4" />
          <Link to="/list-of-address" className="ml-2">
            Manage Address
          </Link>
        </li>

        <hr className="my-2" />
        <li className="nav-item flex items-center text-black hover:text-blue-600 transition duration-200 text-[15px]">
          <CiHeart className="w-4 h-4" />
          <Link to="/wishlist" className="ml-2">
            My Wishlist
          </Link>
        </li>

        <hr className="my-2" />
        <li className="nav-item flex items-center text-black hover:text-blue-600 transition duration-200 text-[15px]">
          <FaHandsHelping className="w-4 h-4" />
          <Link to="/contact" className="ml-2">
            Support & Feedback
          </Link>
        </li>
        <hr className="my-2" />
        <li
          onClick={handleClickOnLogout}
          className="nav-item flex items-center text-[15px] text-black hover:text-blue-600 transition duration-200 mt-auto cursor-pointer"
        >
          <IoIosLogOut className="w-4 h-4" />
          <span className="ml-2">Log Out</span>
        </li>
      </ul>
    </div>
  );
};

export default AccountSidebar;
