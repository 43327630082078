import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { IsLoggedIn } from "../../slice/UserSlice";
import Loader from "../../common/loader/Loader";
import { allProduct } from "../../slice/CartSlice";
import { useEffect, useState } from "react";

const Subtotal = (props: any) => {
  let {
    handleClickOnPayNowWhenUserIsLoggedIn,
    handleClickOnPayNowWhenUserIsNotLoggedIn,
    isLoadingWhilePayment,
  } = props;
  const userIsLoggedIn = useSelector(IsLoggedIn);
  const allProducts = useSelector(allProduct);
  const [totalAmount, setTotalAmount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [percentageOff, setPercentageOff] = useState(0);
  const { subtotal, shippingCharges, total, flatTenPercentOff, totalTax } =
    useSelector((state: RootState) => state.cart);

  useEffect(() => {
    // Calculate total amount

    let newTotalAmount = 0;
    allProducts.forEach((product: any) => {
      newTotalAmount += product?.regularPrice * (product?.quantityByUser || 0);
    });

    // Assuming subtotal is defined elsewhere or calculated dynamically
    const newSubtotal = subtotal; // Replace with actual logic to get subtotal

    // Calculate discount amount and percentage off
    const newDiscountAmount = newTotalAmount - newSubtotal;
    const newPercentageOff = (newDiscountAmount / newTotalAmount) * 100;

    // Update state
    setTotalAmount(() => newTotalAmount);
    setDiscountAmount(() => newDiscountAmount);
    setPercentageOff(() => newPercentageOff);
  }, [allProducts, subtotal]);

  return (
    <div className="mt-6 sm:!mt-[-40px] w-full basis-[30%] sm:basis-[96%] space-y-6  lg:mt-0 lg:max-w-xs xl:max-w-md">
      <div className="flow-root">
        <div className="-my-3 divide-y divide-gray-200 ">
          <dl className="flex items-center justify-between gap-4 py-3">
            <span className="font-medium text-normal">Total amount</span>
            <span>₹{totalAmount.toFixed(2)}</span>
          </dl>

          <dl className="flex items-center justify-between gap-4 py-3">
            <span className="font-medium text-normal text-green-600">
              Discount ({percentageOff.toFixed(2)}%)
            </span>
            <span className="text-green-600">
              -₹{discountAmount.toFixed(2)}
            </span>
          </dl>
          <dl className="flex items-center justify-between gap-4 py-3">
            <dt className="text-base font-normal text-blue-900 font-[550]">
              Subtotal
            </dt>
            <dd className="text-base font-medium text-gray-900">
              ₹{subtotal.toFixed(2)}
            </dd>
          </dl>
          {flatTenPercentOff ? (
            <dl className="flex items-center justify-between gap-4 py-3">
              <span className="font-medium text-normal text-green-600">
                10% Off Orders above 1Lakh
              </span>
              <span className="text-green-600">
                -₹{flatTenPercentOff.toFixed(2)}
              </span>
            </dl>
          ) : (
            ""
          )}

          <dl className="flex items-center justify-between gap-4 py-3">
            <dt className="text-base font-normal text-blue-900 font-[550]">
              Total Tax
            </dt>
            <dd className="text-base font-medium text-gray-900 ">
              <span>₹{totalTax.toFixed(2)}</span>
            </dd>
          </dl>
          <dl className="flex items-center justify-between gap-4 py-3">
            <dt className="text-base font-normal text-blue-900 font-[550]">
              Shipping Charges
            </dt>
            <dd className="text-base font-medium text-gray-900 ">
              <span className="line-through">
                ₹{shippingCharges.toFixed(2)}
              </span>{" "}
              ₹0
            </dd>
          </dl>

          <dl className="flex items-center justify-between gap-4 py-3">
            <dt className="text-base font-bold text-gray-900 "> Grand Total</dt>
            <dd className="text-base font-bold text-gray-900 ">
              ₹{total.toFixed(2)}
            </dd>
          </dl>
          <p
            onClick={
              userIsLoggedIn
                ? handleClickOnPayNowWhenUserIsLoggedIn
                : handleClickOnPayNowWhenUserIsNotLoggedIn
            }
            className="w-[80%] text-sm cursor-pointer flex items-center justify-center bg-primary text-white py-2 px-2 rounded-lg text-md w-84 m-auto relative"
          >
            Pay Now
            {isLoadingWhilePayment && (
              <div className="absolute top-[-18px] right-[40%] sm:text-center sm:w-[100%] sm:flex sm:justify-center sm:items-center  sm:left-[32px] sm:top-[-2px]">
                <Loader />
              </div>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
export default Subtotal;
