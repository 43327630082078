import React, { useEffect, useState, useRef } from "react";
import { FaShoppingCart } from "react-icons/fa";
import {
  IoMdHeartEmpty,
  IoMdHeart,
  IoIosArrowDropright,
  IoIosArrowDropleft,
} from "react-icons/io";
import Loader from "../../common/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { addProduct } from "../../slice/CartSlice";
import { Link, useNavigate } from "react-router-dom";
import { IsLoggedIn } from "../../slice/UserSlice";
import {
  addToWishlist,
  removeFromWishlist,
  wishListProducts,
} from "../../slice/WishlistSlice";
import { AppDispatch } from "../../store/store";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import {
  addProductForComparison,
  compareProducts,
  removeProductForComparison,
  setSubCategoryIdForComparison,
  subCategoryIdForComparison,
} from "../../slice/CompareProductsSlice";
import { showErrorToast } from "../../utils/toast";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ProductForSale = (props: any) => {
  const subCategoryIdForCompare = useSelector(subCategoryIdForComparison);
  const compareTheseProducts = useSelector(compareProducts);
  let { products, heading, setProduct, relatedProductPage } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const wishList = useSelector(wishListProducts);
  const userIsLoggedIn = useSelector(IsLoggedIn);

  const handleAddToCart = async (product: any, index: number) => {
    let updateAllProduct = [...products];

    await dispatch(
      addProduct({
        ...product,
        total: Number(product.salePrice) * product.quantityByUser,
      })
    );

    updateAllProduct[index].addToCartStatus = "Update Cart";
    await setProduct(() => updateAllProduct);
  };

  const handleIncrementForQuantity = (index: number) => {
    let newProduct = [...products];
    newProduct[index].quantityByUser =
      newProduct[index].quantityByUser !== newProduct[index].totalStock
        ? newProduct[index].quantityByUser + 1
        : newProduct[index].quantityByUser;

    setProduct((prevState: any) => {
      return newProduct;
    });
  };

  const handleDecrementForQuantity = (index: number) => {
    let newProduct = [...products];
    newProduct[index].quantityByUser =
      newProduct[index].quantityByUser > 1
        ? newProduct[index].quantityByUser - 1
        : newProduct[index].quantityByUser;
    setProduct((prevState: any) => {
      return newProduct;
    });
  };

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -400, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 400, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };
  const handleClickOnCompare = (product: any) => {
    const isProductInComparison = compareTheseProducts.some(
      (compProduct: any) => compProduct._id === product._id
    );

    if (!isProductInComparison) {
      // If the product is not in the comparison list and the list has less than 5 products
      if (compareTheseProducts.length < 5) {
        if (
          !subCategoryIdForCompare ||
          product.subCategoryId === subCategoryIdForCompare
        ) {
          dispatch(addProductForComparison(product));
          dispatch(setSubCategoryIdForComparison(product?.subCategoryId));
        } else {
          showErrorToast("Please compare similar products!");
        }
      }
    } else {
      // If the product is already in the comparison list, remove it
      dispatch(removeProductForComparison(product._id));
    }
  };

  return (
    <div
      className="sm:pt-[0.5rem] mx-auto max-w-[95%]  no-scrollbar mt-[1rem]
    overflow-x-auto scrollbar-thin scrollbar-thumb-rounded
    relative"
    >
      <h2
        className={
          heading === "New Launches" || heading === "Recommended Products"
            ? "font-semibold text-lg sm:text-sm z-[9] absolute text-black"
            : "font-semibold text-lg sm:text-sm z-[9]"
        }
      >
        {heading}
      </h2>
      <button
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
        }}
        onClick={scrollRight}
        className="absolute right-[4px] sm:hidden text-[2rem] bg-white h-[70px] flex justify-center items-center w-[50px]  top-[50%] text-black transform translate-y-[-50%] z-10"
        aria-label="Scroll Right"
      >
        <MdOutlineKeyboardArrowRight />
      </button>
      <div
        ref={containerRef}
        className="flex p-2 md:p-0 overflow-x-auto no-scrollbar mt-[1rem] flex-row"
        style={{ overflowX: "scroll" }} // Hide the scrollbar
      >
        {products?.length > 0 &&
          products?.map((product: any, index: number) => (
            <div
              className="item flex-none m-[1rem] bg-white sm:basis-[35%] sm:m-[0.4rem] basis-[20%] flex-shrink-0 p-2 sm:p-[0.5rem] sm:pb-[1rem] transform transition-transform duration-300 ease-in-out relative rounded-lg flex flex-col justify-between"
              style={{
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              }}
              key={index}
            >
              {" "}
              {/* <p className="text-sm flex items-center justify-start ">
                <input
                  className="border-[0.5px] mr-[0.45rem] cursor-pointer border-solid border-[black] rounded-lg"
                  type="checkbox"
                  checked={
                    compareTheseProducts.length > 0
                      ? compareTheseProducts.some(
                          (compProduct: any) => compProduct._id === product._id
                        )
                      : false
                  }
                  onClick={() => handleClickOnCompare(product)}
                />
                Compare
              </p> */}
              <Link
                to={`/product?name=${product.productName}&product_id=${
                  product._id ? product._id : product.id
                }`}
                className="flex justify-center items-center"
              >
                <LazyLoadImage
                  effect="blur"
                  src={product?.images[0]?.url}
                  alt={`Carousel item ${index + 1}`}
                  className="w-full h-[220px] !flex justify-center items-center  sm:h-[100px] object-contain rounded-lg transition-transform duration-300 transform hover:scale-105"
                />
              </Link>
              {heading !== "Related Products" && (
                <div className="absolute cursor-pointer top-0 sm:top-0 right-4 sm:right-[1px] text-red-500 sm:text-[1rem] px-2 py-1  text-2xl font-bold hover:bg-red">
                  {!product.addedToWishlist ? (
                    <IoMdHeartEmpty
                      onClick={async () => {
                        if (userIsLoggedIn) {
                          await dispatch(addToWishlist(product));
                          await setProduct((prevAllProduct: any) => {
                            return prevAllProduct.map(
                              (item: any, i: number) => {
                                if (i === index) {
                                  return {
                                    ...item,
                                    addedToWishlist: true,
                                  };
                                }
                                return item;
                              }
                            );
                          });
                        } else {
                          navigate("/login");
                        }
                      }}
                      // onClick={async () => {
                      //   await dispatch(addToWishlist(product));
                      //   await setProduct((prevAllProduct: any) => {
                      //     prevAllProduct[index].addedToWishlist = true;
                      //     return prevAllProduct;
                      //   });
                      // }}
                      className="text-red hover:fill-red"
                    />
                  ) : (
                    <IoMdHeart
                      onClick={async () => {
                        if (userIsLoggedIn) {
                          await dispatch(removeFromWishlist(product));
                          await setProduct((prevAllProduct: any) => {
                            return prevAllProduct.map(
                              (item: any, i: number) => {
                                if (i === index) {
                                  return {
                                    ...item,
                                    addedToWishlist: false,
                                  };
                                }
                                return item;
                              }
                            );
                          });
                        } else {
                          navigate("/login");
                        }
                      }}
                    />
                  )}
                </div>
              )}
              <div className="text-center flex-grow">
                <p className="text-[#f55a01] text-lg sm:text-[0.78rem] font-semibold">
                  ₹{product.salePrice}
                  <span className="line-through text-[0.75rem] ml-[0.3rem] text-gray-500">
                    ₹{product.regularPrice}
                  </span>
                </p>
                <p
                  className={
                    relatedProductPage
                      ? "text-gray-900 mt-[0.4rem]  text-[0.9rem] font-[450] sm:text-xs sm:font-[400] line-clamp-4"
                      : "text-gray-900 mt-[0.4rem]  text-[0.9rem] font-[450] sm:text-xs sm:font-[400] line-clamp-3"
                  }
                >
                  <Link
                    to={`/product?name=${product.productName}&product_id=${product._id}`}
                  >
                    {product.productName}
                  </Link>
                </p>
              </div>
              {/* <!-- quantity  --> */}
              {!relatedProductPage ? (
                product.totalStock === 0 ? (
                  <p className="text-blue-800 text-sm text-center">
                    Out of stock!
                  </p>
                ) : (
                  <>
                    <div
                      className={
                        "py-3 px-1 sm:px-[0.15rem] bg-white w-[80%] rounded-lg flex items-center ml-[1.5rem] sm:m-0"
                      }
                    >
                      {product.addToCartStatus !== "Add to cart" && (
                        <div className="w-full flex justify-between items-center gap-x-3">
                          <div className="relative w-full">
                            <input
                              id="hs-validation-name-error"
                              className="w-[90%] sm:w-[45px] sm:h-[20px] text-sm outline-none py-[0.1rem] border-[0.8px] border-solid border-[#ddd] pl-[0.8rem] rounded-lg bg-transparent border-0 text-gray-800 focus:ring-0"
                              type="number"
                              value={product?.quantityByUser}
                              data-hs-input-number-input=""
                              aria-describedby="hs-validation-name-error-helper"
                            />
                          </div>
                          <div className="flex justify-end items-center gap-x-1.5">
                            <button
                              type="button"
                              onClick={() => handleDecrementForQuantity(index)}
                              className="size-6 sm:size-[1.25rem] inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                              data-hs-input-number-decrement=""
                            >
                              <svg
                                className="flex-shrink-0 size-3.5"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path d="M5 12h14"></path>
                              </svg>
                            </button>
                            <button
                              type="button"
                              className="size-6 sm:size-[1.25rem] inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                              data-hs-input-number-increment=""
                              onClick={() => handleIncrementForQuantity(index)}
                            >
                              <svg
                                className="flex-shrink-0 size-3.5"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path d="M5 12h14"></path>
                                <path d="M12 5v14"></path>
                              </svg>
                            </button>
                          </div>
                        </div>
                      )}
                    </div>

                    {product.quantityByUser == product.totalStock ? (
                      <p
                        className="text-xs text-center text-blue-600"
                        id="hs-validation-name-error-helper"
                      >
                        Only {product.totalStock} left in stock
                      </p>
                    ) : (
                      ""
                    )}
                    <div className="flex justify-center items-center w-[100%]">
                      <button
                        onClick={() => {
                          handleAddToCart(product, index);
                        }}
                        className="bg-indigo-700 text-center w-[75%] sm:mt-0 xss:w-[100%] relative sm:m-0  mt-[0.2rem]  m-[0.9rem] rounded-lg text-white p-[0.4rem] sm:py-[0.2rem] text-sm sm:text-[0.63rem] hover:bg-blue-600"
                      >
                        {product.addToCartStatus}
                        <FaShoppingCart className="inline mb-[0.2rem] ml-[0.2rem]" />
                        {product.isLoading && (
                          <div className="absolute top-[-18px] left-[14px]">
                            <Loader />
                          </div>
                        )}
                      </button>
                    </div>
                  </>
                )
              ) : (
                ""
              )}
            </div>
          ))}
      </div>
      <button
        onClick={scrollLeft}
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
        }}
        className="absolute left-[4px] sm:hidden text-[2rem] bg-white h-[70px] flex justify-center items-center w-[50px]  top-[50%] text-black transform translate-y-[-50%] z-10"
        aria-label="Scroll Right"
      >
        <MdOutlineKeyboardArrowLeft />
      </button>
    </div>
  );
};

export default ProductForSale;
