import React, { useEffect } from "react";
import CompareItems from "./CompareItems";
import {
  clearComparison,
  compareProducts,
  subCategoryIdForComparison,
} from "../../slice/CompareProductsSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { showErrorToast } from "../../utils/toast";

const Compare = ({ isComparisonPage }: any) => {
  let dispatch = useDispatch<AppDispatch>();
  const subCategoryId = useSelector(subCategoryIdForComparison);
  const compareTheseProducts = useSelector(compareProducts);
  const navigate = useNavigate();

  const clearAll = () => {
    dispatch(clearComparison());
  };

  const handleClickOnCompare = () => {
    let url = "/comparison";

    if (compareTheseProducts.length >= 2) {
      const productIds = compareTheseProducts
        .map(
          (product: any, index: number) => `product${index + 1}=${product._id}`
        )
        .join("&");
      url += `?${productIds}`;
      navigate(url + `&subCategoryId=${subCategoryId}`);
    } else {
      showErrorToast("Please select at least 2 products to compare");
    }
  };

  return (
    <div
      className={
        isComparisonPage
          ? "z-[99999] animate-in fade-in duration-100 flex mx-auto flex-wrap  justify-betweeen fixed top-[100px] right-0 left-0 w-full bg-gray-100 p-[1.5rem] sm:p-[1rem] no-scrollbar overflow-scroll"
          : "z-[99999] animate-in fade-in duration-1000 flex mx-auto flex-wrap  justify-betweeen fixed bottom-0 right-0 left-0 w-full bg-gray-100 p-[1.5rem] sm:p-[1rem] no-scrollbar overflow-scroll"
      }
    >
      <div
        className={
          isComparisonPage
            ? "flex basis-[100%] justify-center flex-wrap sm:basis-[100%]  overflow-x-scroll"
            : "flex basis-[85%] flex-wrap sm:basis-[100%]  overflow-x-scroll"
        }
      >
        <CompareItems isComparisonPage={isComparisonPage} />
      </div>
      {!isComparisonPage && (
        <div className="m-4 sm:m-2 flex flex-col basis-[10%] sm:basis-[100%]  justify-center sm:max-h-[100px]">
          <button
            onClick={handleClickOnCompare}
            className="px-4  py-2 sm:py-1 sm:px-2 bg-[#FED613] sm:text-xs text-black rounded-lg"
          >
            Compare
          </button>
          <button
            onClick={clearAll}
            className="px-4 py-2 bg-gray-300  sm:py-1 sm:px-2 text-black sm:text-xs rounded-lg  mt-[0.5rem]"
          >
            Clear All
          </button>
        </div>
      )}
    </div>
  );
};

export default Compare;
